<template>
  <!--**********************************
            Main wrapper start
  ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
            Content body start
    ***********************************-->
    <div class="content-body pt-5">
      <div class="container-fluid">
        <div class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3">
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.partners[4].commission_page[0].Commission }}</h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header pb-2 d-block flex-wrap border-0">
                <div class="about-commission mb-5">
                  <p>{{ lan.partners[4].commission_page[0].Please_verify }}</p>
                  <div class="ull my-3">
                    <p>{{ lan.partners[4].commission_page[0].The_following_items }}</p>
                    <ul>
                      <li>{{ lan.partners[4].commission_page[0].Board_and_lodging }}</li>
                      <li>{{ lan.partners[4].commission_page[0].All_fees }}</li>
                      <li>{{ lan.partners[4].commission_page[0].Invoiced_cancellations_fees }}</li>
                    </ul>
                  </div>
                  <div class="ull my-3">
                    <p>{{ lan.partners[4].commission_page[0].The_following_is_also_valid_items_for_commissions }}</p>
                    <ul>
                      <li>{{ lan.partners[4].commission_page[0].A_booking_where_all }}</li>
                    </ul>
                  </div>

                  <p>{{ lan.partners[4].commission_page[0].The_commission_report }}</p>
                </div>

                <div class="row justify-content-between both reportTable">
                  <div class="col-lg-6">
                    <span>{{ lan.partners[4].commission_page[0].Details_of_booking }}</span>
                    <table class="bg-info-hover tr-rounded order-tbl topTable mt-2">
                      <tbody>
                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Customer_Name }}</td>
                          <td v-if="customer_account_detail_name != ''">
                            {{ customer_account_detail_name.name }}
                          </td>
                          <td v-else></td>
                        </tr>

                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Date_of_conference }}</td>
                          <td>{{ opportunity_detail.date }}</td>
                        </tr>
                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Type_of_conference }}</td>
                          <td>{{ opportunity_detail.type_of_conference }}</td>
                        </tr>
                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Number_of_participants }}</td>
                          <td>{{ opportunity_detail.number_of_participants }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-6">
                    <span>{{ lan.partners[4].commission_page[0].Contact_person_at_Konferensbokarna }}</span>
                    <table class="bg-info-hover tr-rounded order-tbl topTable mt-2">
                      <tbody>

                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].name }}</td>
                          <td>{{ account_owner.first_name + " " + account_owner.last_name }}</td>
                        </tr>
                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Telephone }}</td>
                          <td>{{ account_owner.phone }}</td>
                        </tr>
                        <tr>
                          <td>{{ lan.partners[4].commission_page[0].Email }}</td>
                          <td>{{ account_owner.email }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="status-booking lkk">
                  <label>{{ lan.partners[4].commission_page[0].Status_of_booking }}<span>*</span><span
                      style="color: red">{{
                        v$.status.$error ? " is required" : ""
                      }}</span></label>
                  <table class="bg-info-hover tr-rounded order-tbl topTable mt-2">
                    <tbody>
                      <tr>
                        <td>
                          <input type="radio" id="html" name="fav_language"
                            value="The conference was conducted as planned" v-model="state.status"
                            :disabled="isButtonActive" />
                          &nbsp;
                          <label for="html">{{
                            lan.partners[4].commission_page[0].The_conference_conducted_planned
                            }}</label>
                        </td>
                        <td>
                          <input type="radio" id="html" name="fav_language"
                            value="The conference was reschedule to another date" v-model="state.status"
                            :disabled="isButtonActive" />
                          &nbsp;
                          <label for="html">{{
                            lan.partners[4].commission_page[0].The_conference_reschedule_another_date
                            }}</label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="radio" id="html" name="fav_language"
                            value="The conference was canceled and a cancellation fee was paid" v-model="state.status"
                            :disabled="isButtonActive" />
                          &nbsp;
                          <label for="html">{{
                            lan.partners[4].commission_page[0].The_conference_canceled_with_paid
                            }}</label>
                        </td>
                        <td>
                          <input type="radio" id="css" name="fav_language"
                            value="The conference was canceled and no cancellation fee was paid" v-model="state.status"
                            :disabled="isButtonActive" />
                          &nbsp;
                          <label for="css">{{
                            lan.partners[4].commission_page[0].The_conference_canceled_no_paid
                            }}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="state.status == 'The conference was reschedule to another date'"
                  class="d-flex align-items-baseline devide mt-4">
                  <div class="mr-2">
                    <strong><label>{{ lan.partners[4].commission_page[0].Rescheduled_to }}:</label></strong>
                  </div>
                  <div>
                    <input type="date" class="init" name="date" v-model="state.reschedule_date"
                      placeholder="excl VAT/inc VAT " :disabled="isButtonActive" />
                  </div>
                </div>

                <div v-if="state.status == 'The conference was conducted as planned'">
                  <div class="card-body tab-content both p-0">
                    <div class="tab-pane active show fade" id="monthly">
                      <div class="table-responsive">
                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{ lan.partners[4].commission_page[0].Final_commission_to_report }}
                          </h4>
                        </div>

                        <div class="row mx-0">
                          <div class="col-7 px-0">
                            <table class="bg-info-hover tr-rounded order-tbl topTable w-100" style="white-space: normal;">
                              <tbody>

                                <tr>
                                  <td>{{ lan.partners[4].commission_page[0].Expected_value_of_the_booking }}</td>
                                  <td class="text-right">{{ quote_detail.price }}</td>
                                </tr>
                                <tr>
                                  <td>{{ lan.partners[4].commission_page[0].Expected_commission }}</td>
                                  <td v-if="!ExpectedCommission.isModal" class="text-right">{{ ExpectedCommission.single }}</td>
                                  <td v-if="ExpectedCommission.isModal" class="text-right">
                                    <span>Single: {{ ExpectedCommission.single }}</span>
                                    <hr class="my-1">
                                    <span>Split: {{ ExpectedCommission.split }}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>{{ lan.partners[4].commission_page[0].Commission_percentage_for_this_quote }}</td>
                                  <td v-if="!CommissionPercentageForThisQuote.isModal" class="text-right">{{
                                    CommissionPercentageForThisQuote.single }}%</td>
                                  <td v-if="CommissionPercentageForThisQuote.isModal" class="text-right">
                                    <span>Single: {{ CommissionPercentageForThisQuote.single }}%</span>
                                    <hr class="my-1">
                                    <span>Split: {{ CommissionPercentageForThisQuote.split }}%</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-5">
                            <div>
                              <h4>{{ lan.partners[4].commission_page[0].This_is_our_current_invoice_information }}</h4>
                              <p class="text-left fs-16 mb-0">{{account_details.name}}</p>
                              <p class="text-left fs-16 mb-0">{{account_details.invoice_address}}</p>
                              <p class="text-left fs-16 mb-0">{{account_details.invoice_email}}</p>
                            </div>
                            <div class="mt-3">
                              <h4>{{ lan.partners[4].commission_page[0].Is_this_correct }}</h4>
                                <span class="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    id="html"
                                    name="correct"
                                    value="Yes"
                                    v-model="infoInvoice"
                                  />
                                  &nbsp;
                                  <label for="html" class="mb-0">{{ lan.partners[4].commission_page[0].Yes }}</label>
                                </span>
                                <span class="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    id="html"
                                    name="correct"
                                    value="No"
                                    v-model="infoInvoice"
                                  />
                                  &nbsp;
                                  <label for="html" class="mb-0">{{ lan.partners[4].commission_page[0].No_we_need_to_change }}</label>
                                </span>
                            </div>
                          </div>
                        </div>

                        <table v-if="account_details" class="bg-info-hover tr-rounded order-tbl topTable">
                          <tbody>

                            <tr v-if="account_details.commission_model == 'Single commission model'">
                              <td>{{
                                lan.partners[4].commission_page[0].Final_value_accomodation_conference_halls_for_the_booking
                                }} 1</td>
                              <td>
                                <input v-model="price" type="number" class="p-1"
                                :placeholder="lan.partners[4].commission_page[0].Final_value" min="0" :disabled="isButtonActive" />
                              </td>
                            </tr>
                            <tr v-if="account_details.commission_model == 'Split commission model'">
                              <td>{{
                                lan.partners[4].commission_page[0].Final_value_accomodation_conference_halls_and_meals_for_the_booking
                                }} 2</td>
                              <td><input v-model="price" type="number" class="form-control h-auto w-auto p-1"
                                :placeholder="lan.partners[4].commission_page[0].Final_value" min="0" :disabled="isButtonActive" />
                              </td>
                            </tr>
                            <tr v-if="account_details.commission_model == 'Split commission model'">
                              <td>{{ lan.partners[4].commission_page[0].Final_value_meals_the_booking }}</td>
                              <td><input v-model="meals_of_price" type="number" class="form-control h-auto w-auto p-1"
                                :placeholder="lan.partners[4].commission_page[0].Final_value" min="0" :disabled="isButtonActive" />
                              </td>
                            </tr>
                            <tr>
                              <td>{{ lan.partners[4].commission_page[0].Final_value_of_the_commissioin }}</td>
                              <td class="text-right">{{ finalValueCommission }}</td>
                            </tr>
                          </tbody>
                        </table>

                        <!-- <div class="table-responsive">
                        <table
                          class="text-center bg-info-hover tr-rounded order-tbl"
                        >
                          <thead>
                            <tr>
                              <th>{{ lan.partners[4].commission_page[0].Quote_Line }}</th>
                              <th>{{ lan.partners[4].commission_page[0].date }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Number_in_Quote }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Number_that_was_conducted }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Price_ex_Vat }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Total }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Provision_in_percent }}</th>
                              <th>{{ lan.partners[4].commission_page[0].Provision_in_Sek }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in price_for_conference_room"
                              :key="index"
                            >
                              <td>{{ data.product.product_description }}</td>
                              <td>{{ data.date }}</td>
                              <td>{{ data.request_size_we_can_offer }}</td>
                              <td>
                                <input
                                  v-model="data.no_of_people_came"
                                  type="number"
                                  class="p-1"
                                  placeholder="People Came"
                                  min="0"
                                />
                              </td>
                              <td>{{ data.fee_confrence_room }}</td>
                              <td>{{ data.fee_confrence_room }}</td>
                              <td>{{ provision }}</td>
                              <td>
                                {{
                                  (data.fee_confrence_room * provision) / 100
                                }}
                              </td>
                            </tr>
                            <tr
                              v-for="(data, index) in price_for_accomodation"
                              :key="index"
                            >
                              <td>{{ data.product.product_description }}</td>
                              <td>{{ data.date }}</td>
                              <td>{{ data.room }}</td>
                              <td>
                                <input
                                  v-model="data.no_of_people_came"
                                  type="number"
                                  class="p-1"
                                  placeholder="People Came"
                                  min="0"
                                />
                              </td>
                              <td>
                                {{ data.price_for_this_quote }} *
                                {{ data.no_of_people_came }}
                              </td>
                              <td>
                                {{
                                  data.price_for_this_quote *
                                  data.no_of_people_came
                                }}
                              </td>
                              <td>{{ provision }}</td>
                              <td>
                                {{
                                  (data.price_for_this_quote *
                                    data.no_of_people_came *
                                    provision) /
                                  100
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                price_for_other_products &&
                                price_for_other_products.length
                              "
                              v-for="(data, index) in price_for_other_products"
                              :key="index"
                            >
                              <td>{{ data.product.product_description }}</td>
                              <td>{{ data.date }}</td>
                              <td>{{ data.room_we_offer }}</td>
                              <td>
                                <input
                                  v-model="data.no_of_people_came"
                                  type="number"
                                  class="p-1"
                                  placeholder="People Came"
                                  min="0"
                                />
                              </td>
                              <td>
                                {{ data.price_for_this_quote }} *
                                {{ data.no_of_people_came }}
                              </td>
                              <td>
                                {{
                                  data.price_for_this_quote *
                                  data.no_of_people_came
                                }}
                              </td>
                              <td>{{ provision }}</td>
                              <td>
                                {{
                                  (data.price_for_this_quote *
                                    data.no_of_people_came *
                                    provision) /
                                  100
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="price_for_meals && price_for_meals.length"
                              v-for="(data, index) in price_for_meals"
                              :key="index"
                            >
                              <td>{{ data.product.product_description }}</td>
                              <td>{{ data.date }}</td>
                              <td>{{ data.room_we_offer }}</td>
                              <td>
                                <input
                                  v-model="data.no_of_people_came"
                                  type="number"
                                  class="p-1"
                                  placeholder="People Came"
                                  min="0"
                                />
                              </td>
                              <td>
                                {{ data.price_for_this_quote }} *
                                {{ data.no_of_people_came }}
                              </td>
                              <td>
                                {{
                                  data.price_for_this_quote *
                                  data.no_of_people_came
                                }}
                              </td>
                              <td>{{ provision }}</td>
                              <td>
                                {{
                                  (data.price_for_this_quote *
                                    data.no_of_people_came *
                                    provision) /
                                  100
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{{ lan.partners[4].commission_page[0].Total }}</td>
                              <td>
                                {{
                                  provision_accomodation +
                                  provision_other_products +
                                  provision_conference +
                                  provision_meals_products
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> -->

                      </div>
                    </div>
                  </div>

                  <div>
                    <h5>{{ lan.partners[4].commission_page[0].Please_upload_copy_of_the_customer_invoice }}</h5>
                    <button class="filter-btn ml-0">
                      <input type="file" name="file" placeholder="Submitted file" @change="onFileChange2"
                        :disabled="isButtonActive" />
                      Upload
                    </button>
                    <div v-if="file_preview" class="d-flex flex-wrap mb-4" style="overflow: auto;">
                      <div v-if="file_information && file_information.type != 'application/pdf'" class="column">
                        <img :src="file_preview" class="img-flow my-0" alt="Nature" />
                      </div>

                      <div v-if="file_information && file_information.type == 'application/pdf'"
                        class="col-lg-12 pr-md-3 pr-0 mb-md-0 mb-4">
                        <embed :src="file_preview" type="video/webm" width="100%"
                          style="max-height: 70rem; min-height: 20rem" />
                      </div>
                    </div>
                  </div>

                  <div v-if="infoInvoice === 'No'">
                    <span class="fs-16">{{ lan.partners[4].commission_page[0].Invoice_information }}</span><br>
                    <span class="fs-16">{{ lan.partners[4].commission_page[0].Please_write_your_invoicing_information_below }}</span>
                    <div class="row">
                      <div class="col-lg-8">
                        <textarea name="" id="" v-model="invoice_information" cols="4" rows="5"
                          :disabled="isButtonActive"></textarea>
                        </div>
                      </div>
                  </div>

                  <div>
                    <span class="fs-16">{{ lan.partners[4].commission_page[0].information_Konferensbokarna }}</span><br>
                    <span class="fs-16">{{
                      lan.partners[4].commission_page[0].Please_write_information_regarding_this_commission_to_Konferensbokarna
                      }}</span>
                      <div class="row">
                        <div class="col-lg-8">
                          <textarea name="" id="" v-model="information_to_konference" cols="4" rows="5"
                          :disabled="isButtonActive"></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-5">
                  <button v-if="commission_status != 'Submitted'" class="filter-btn" @click="submit('submit')">
                    <div class="lds-ring" v-show="loading_submit">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <span v-show="!loading_submit">{{ lan.partners[4].commission_page[0].Submit }}</span>
                  </button>
                  <!-- <button v-if="commission_status != 'Submitted'" class="search-btn ml-3" @click="submit('save')">
                          <div class="lds-ring" v-show="loading_save">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-show="!loading_save">{{ lan.partners[4].commission_page[0].Save }}</span>
                        </button> -->
                  <router-link to="/p-commission"><button class="filter-btn">
                      {{ lan.partners[4].commission_page[0].Cancel }}
                    </button></router-link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--**********************************
                    Content body end
                ***********************************-->
  </div>
  <!--**********************************
            Main wrapper end
          ***********************************-->
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Partner Edit Commission",

  setup() {
    const state = reactive({
      errors: [],
      success: [],
      reschedule_date: "",
      status: "",
    });

    const rules = computed(() => {
      return {
        reschedule_date: {},
        status: { required },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      account_details: "",
      opportunity_detail: "",
      quote_detail: "",
      account_owner: "",
      price_of_accomodations_details: "",
      commission_details: "",
      confrence_conduct: "",
      reschedule_date: "",
      number_of_participants: "",
      price: "",
      status: "",
      quote_id: "",
      account_id: "",
      contact_id: "",
      price_for_accomodation: "",
      price_for_meals: "",
      price_for_conference_room: "",
      price_for_other_products: "",
      customer_account_detail_name: "",
      provision: "",
      provision_for_sek_price_for_accomodation: 0,
      provision_for_sek_price_for_other_products: 0,
      provision_for_sek_price_for_meals_products: 0,
      provision_for_sek_price_for_conference_room: 0,
      room_we_offer: "",
      quote_details: "",
      commission_status: "",
      file_preview: "",
      file: "",
      file_information: "",
      meals_of_price: "",
      information_to_konference: "",
      invoice_information: "",
      isButtonActive: false,


      Id: this.$route.params.id,

      loading_save: false,
      loading_submit: false,
      loadingS: true,

      hideRescheduleDate: false,

       infoInvoice: ''
    };
  },
  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast(
  //       "You can't access Partner Portal",
  //       " Warning ",
  //       "warning_1"
  //     );
  //     setTimeout(() => {
  //       alert("First logout then Login partner portal.");
  //       this.$router.back();
  //     }, 3000);
  //   }
  // },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    axios
      .get(axios.defaults.baseURL + "commission/" + this.Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const response = res.data.data[0];
        this.quote_detail = response.quote_detail
        this.account_details = response.account_detail
        this.opportunity_detail = response.opportunity_detail
        this.commission_details = response;

        this.file_preview = response.file;
        this.file_information = response.file_information != '' ? JSON.parse(response.file_information) : '';
        // this.file_information = '';

        this.account_id = response.account_id;
        this.contact_id = response.contact_id;
        this.quote_id = response.quote_id;

        this.state.reschedule_date = response.reschedule_date;
        this.number_of_participants = response.number_of_participants;
        this.price = response.price;
        this.meals_of_price = response.meals_of_price;
        this.state.status = response.status;
        this.provision = response.provision;
        this.commission_status = response.commission_status;
        this.information_to_konference = response.information_to_konference;
        this.invoice_information = response.invoice_information;

        if (this.quote_detail.price_for_accomodation != "") {
          this.price_for_accomodation = JSON.parse(this.quote_detail.price_for_accomodation);
        }
        if (this.quote_detail.price_for_other_products != "") {
          this.price_for_other_products = JSON.parse(this.quote_detail.price_for_other_products);
        }
        if (this.quote_detail.price_for_meals != "") {
          this.price_for_meals = JSON.parse(this.quote_detail.price_for_meals);
        }
        if (this.quote_detail.price_for_conference_room != "") {
          this.price_for_conference_room = JSON.parse(this.quote_detail.price_for_conference_room);
        }
        this.customer_account_detail_name =
          response.customer_account_detail[0];

        this.isButtonActive = this.commission_status == 'Submitted' ? true : false;



        //   get accounts details********************
        // axios
        //   .get(
        //     axios.defaults.baseURL + "account/" + res.data.data[0].account_id,
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.getItem("token"),
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     console.log("res account", res);
        //     this.account_details = res.data.data[0];
        //     console.log("acccount details", this.account_details);
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //   });
        // //   ********************

        // //   ********************
        axios
          .get(
            axios.defaults.baseURL + "contact/" + response.opportunity_detail.opportunity_owner,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.account_owner = res.data.data[0];
          })
          .catch((err) => {
            console.log("err", err);
          });
        // //   ********************
        // //  ******* get quote by id
        // axios
        //   .get(axios.defaults.baseURL + "quote/" + res.data.data[0].quote_id, {
        //     headers: {
        //       Authorization: "Bearer " + localStorage.getItem("token"),
        //     },
        //   })
        //   .then((res) => {
        //     this.quote_details = res.data.data[0];
        //     this.price_for_accomodation = JSON.parse(
        //       res.data.data[0].price_for_accomodation
        //     );
        //     if (res.data.data[0].price_for_other_products != "") {
        //       this.price_for_other_products = JSON.parse(
        //         res.data.data[0].price_for_other_products
        //       );
        //     }
        //     if (res.data.data[0].price_for_meals != "") {
        //       this.price_for_meals = JSON.parse(
        //         res.data.data[0].price_for_meals
        //       );
        //     }

        //     this.price_for_conference_room = JSON.parse(
        //       res.data.data[0].price_for_conference_room
        //     );
        //     this.customer_account_detail_name =
        //       res.data.data[0].customer_account_detail;
        //   })
        //   .catch((err) => {
        //     console.log("quote err", err);
        //   });
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },

    ExpectedCommission: function () {
      var total = {
        single: 0,
        split: 0,
        isModal: false
      }
      if (this.quote_detail && this.account_details) {
        var commissonModal = this.account_details.commission_model == 'Single commission model' ? true : false;
        var commision = this.quote_detail.commission != '' ? this.quote_detail.commission : this.account_details.commission;
        var mealsCommision = this.account_details.commission_model == 'Split commission model' ? this.account_details.meals_commission : null
        if (commissonModal) {
          total.single = this.quote_detail.price * commision / 100;
          total.isModal = false
          return total
        } else {
          total.isModal = true

          var accomodation = this.getCalculationsAccom()
          var meals = this.getCalculationsMeals()
          total.single = Number(accomodation * commision / 100)
          total.split = Number(meals * mealsCommision / 100)
          return total
        }
      } else {
        return total
      }
    },
    finalValueCommission: function () {

      var total = 0

      if (this.quote_detail && this.account_details) {
        var commissonModal = this.account_details.commission_model == 'Single commission model' ? true : false;
        var commision = this.quote_detail.commission != '' ? this.quote_detail.commission : this.account_details.commission;
        var mealsCommision = this.account_details.commission_model == 'Split commission model' ? this.account_details.meals_commission : null
        if (commissonModal) {
          total = this.price * commision / 100;
          return total
        } else {
          total = Number(this.price * commision / 100)
          total = total + Number(this.meals_of_price * mealsCommision / 100)
          return total
        }
      } else {
        return total
      }

    },
    CommissionPercentageForThisQuote: function () {
      var commission = {
        single: 0,
        split: 0,
        isModal: false
      }
      if (this.quote_detail && this.account_details) {
        var commissonModal = this.account_details.commission_model == 'Single commission model' ? true : false;

        if (commissonModal) {
          commission.single = this.quote_detail.commission != '' ? this.quote_detail.commission : this.account_details.commission
          commission.isModal = false;
          return commission
        } else {
          commission.single = this.quote_detail.commission != '' ? this.quote_detail.commission : this.account_details.commission;
          commission.split = this.quote_detail.meals_commission != '' ? this.quote_detail.meals_commission : this.account_details.meals_commission;
          commission.isModal = true;
          return commission
        }

      } else {
        return commission
      }


    },


    provision_accomodation: function () {
      var total = 0;
      for (let i = 0; i < this.price_for_accomodation.length; i++) {
        // console.log("iiii", i)
        total =
          total +
          (this.price_for_accomodation[i].price_for_this_quote *
            this.price_for_accomodation[i].no_of_people_came *
            this.provision) /
          100;

        // console.log('provision sek for accomodation',  this.provision_for_sek_price_for_accomodation)
      }
      this.provision_for_sek_price_for_accomodation = total;
      return total;
    },
    provision_other_products: function () {
      var total = 0;
      for (let i = 0; i < this.price_for_other_products.length; i++) {
        // console.log("iiii", i)
        total =
          total +
          (this.price_for_other_products[i].price_for_this_quote *
            this.price_for_other_products[i].no_of_people_came *
            this.provision) /
          100;

        // console.log('provision sek for accomodation',  this.provision_for_sek_price_for_other_products)
      }
      this.provision_for_sek_price_for_other_products = total;
      return total;
    },
    provision_meals_products: function () {
      var total = 0;
      for (let i = 0; i < this.price_for_meals.length; i++) {
        // console.log("iiii", i)
        total =
          total +
          (this.price_for_meals[i].price_for_this_quote *
            this.price_for_meals[i].no_of_people_came *
            this.provision) /
          100;

        // console.log('provision sek for accomodation',  this.provision_for_sek_price_for_meals_products)
      }
      this.provision_for_sek_price_for_meals_products = total;
      return total;
    },
    provision_conference: function () {
      var total = 0;
      for (let i = 0; i < this.price_for_conference_room.length; i++) {
        // console.log("iiii", i)
        total =
          total +
          (this.price_for_conference_room[i].fee_confrence_room *
            this.provision) /
          100;

        // console.log('provision sek for conference hall',  this.provision_for_sek_price_for_conference_room)
      }
      this.provision_for_sek_price_for_conference_room = total;
      return total;
    },
  },

  methods: {
    getCalculationsAccom() {
      var total = 0;
      for (let i = 0; i < this.price_for_accomodation.length; i++) {
        // console.log("iiii", i)
        total =
          total +
          (this.price_for_accomodation[i].price_for_this_quote *
            this.price_for_accomodation[i].room)

        // console.log('provision sek for accomodation',  this.provision_for_sek_price_for_accomodation)
      }

      for (let j = 0; j < this.price_for_conference_room.length; j++) {
        // console.log("iiii", i)
        if (this.price_for_conference_room[j].fee_confrence_room) {
          total =
            total + parseInt(this.price_for_conference_room[j].fee_confrence_room)
        }
        // console.log('provision sek for conference hall',  this.provision_for_sek_price_for_conference_room)
      }
      return total;
    },
    getCalculationsMeals() {
      var total = 0;
      for (let i = 0; i < this.price_for_meals.length; i++) {
        // console.log("iiii", i)
        total =
          total + this.price_for_meals[i].price_for_this_quote * this.price_for_meals[i].room

        // console.log('provision sek for accomodation',  this.provision_for_sek_price_for_meals_products)
      }
      return total;
    },
    rescheduleHideFunc() {
      if (
        this.state.status == "Cancelled" ||
        this.state.status == "Cancelled with a cancellation fee"
      ) {
        this.hideRescheduleDate = true;
      } else {
        this.hideRescheduleDate = false;
      }
    },
    onFileChange2(event) {
      const maxAllowedSize = 30 * 1024 * 1024
      var size = event.target.files[0].size;
      if (size > maxAllowedSize) {
        this.AddToast(
          this.lan.The_image_should_be_less_than_30MB,
          "Information",
          "info_1"
        );
        return
      }
      this.file_information = {
        file: event.target.files[0],
        preview: URL.createObjectURL(event.target.files[0]),
        name: event.target.files[0].name,
        type: event.target.files[0].type,
        message: '',
      }
      this.file_preview = URL.createObjectURL(event.target.files[0]);
      this.file = event.target.files[0];
    },
    submit(text) {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (text == 'save') {
          this.loading_save = true;
        } else {
          this.loading_submit = true;
        }

        var formData = new FormData();
        formData.append("price", this.price);
        formData.append("meals_of_price", this.meals_of_price);
        formData.append("reschedule_date", this.state.reschedule_date);
        formData.append("file", this.file);
        formData.append("file_information", JSON.stringify(this.file_information));
        formData.append("information_to_konference", this.information_to_konference);
        formData.append("invoice_information", this.invoice_information);
        formData.append("quote_id", this.quote_id);
        formData.append("contact_id", this.contact_id);
        formData.append("account_id", this.account_id);
        formData.append("status", this.state.status);

        axios
          .post(
            axios.defaults.baseURL + "edit-commission/" + this.Id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            if (text == 'save') {
              this.loading_save = false;
            } else {
              this.loading_submit = false;
            }
            (this.loading_save = false),
            this.$store.dispatch("checkErrorAndSendToast", [res, 'Success']);
            this.commission_status = "Submitted"
            this.$router.push({ path: "/p-commission" });
          })
          .catch((err) => {
            if (text == 'save') {
              this.loading_save = false;
            } else {
              this.loading_submit = false;
            }
            console.log("err", err);
            if (err.response.data.message == 'array_walk_recursive(): Argument #1 ($array) must be of type array, null given') {
              this.AddToast(this.lan.Submit_successfully, "Success", "success_1");
              this.$router.push({ path: "/p-commission" });
            } else {
              this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
            }
          });


        // var formData = new FormData();

        // formData.append("opportunity_id", this.opportunity_id);
        // formData.append("account_id", this.quote_details.account_id);
        // formData.append("contact_id", this.quote_details.contact_id);
        // formData.append("response", this.quote_details.response);
        // formData.append("comments", this.quote_details.comments);
        // formData.append("total_discount", this.quote_details.total_discount);
        // formData.append(
        //   "customer_account_id",
        //   this.quote_details.customer_account_id
        // );
        // formData.append("commission", this.quote_details.commission);
        // // formData.append("description", this.quote_details.description);
        // formData.append("booking_terms", this.quote_details.booking_terms);
        // formData.append("price_should_be", this.quote_details.price_should_be);
        // formData.append(
        //   "courses_in_dinner",
        //   this.quote_details.courses_in_dinner
        // );
        // formData.append(
        //   "number_of_participants",
        //   this.quote_details.number_of_participants
        // );
        // formData.append(
        //   "info_regarding_file",
        //   this.quote_details.info_regarding_file
        // );
        // formData.append("comments2", this.quote_details.comments2);
        // formData.append(
        //   "type_of_conference",
        //   this.quote_details.type_of_conference
        // );
        // formData.append("additional_comment", this.quote_details.additional_comment);
        // formData.append("booked", this.quote_details.booked);
        // formData.append("lost_reason", this.quote_details.lost_reason);
        // formData.append("preliminary", this.quote_details.preliminary);
        // formData.append("is_new_partner", this.quote_details.is_new_partner);

        // formData.append("opportunity_id", this.quote_details.opportunity_id);
        // formData.append("price", this.quote_details.price);
        // formData.append("submitter_name", this.quote_details.submitter_name);
        // formData.append("submitter_phone", this.quote_details.submitter_phone);
        // formData.append("submitter_email", this.quote_details.submitter_email);
        // formData.append(
        //   "price_for_conference_room",
        //   JSON.stringify(this.price_for_conference_room)
        // );
        // formData.append(
        //   "price_for_accomodation",
        //   JSON.stringify(this.price_for_accomodation)
        // );
        // formData.append(
        //   "price_for_other_products",
        //   JSON.stringify(this.price_for_other_products)
        // );

        // formData.append("text", this.quote_details.text);

        // formData.append(
        //   "free_of_charge_cancellation",
        //   this.quote_details.free_of_charge_cancellation
        // );
        // formData.append(
        //   "free_of_charge_cancellation_date",
        //   ""
        // );
        // formData.append("price_for_meals", this.quote_details.price_for_meals);

        // formData.append("questions", this.quote_details.questions);
        // formData.append(
        //   "additional_questions",
        //   this.quote_details.additional_questions
        // );
        // formData.append(
        //   "additional_information",
        //   this.quote_details.additional_information
        // );
        // formData.append("attachments[]", this.quote_details.all_attachments);
        // formData.append("status", this.quote_details.status);
        // formData.append("date", this.quote_details.date);

        // axios
        //   .post(
        //     axios.defaults.baseURL + "edit-quote/" + this.quote_id,
        //     // axios.defaults.baseURL + "edit-quote/" + this.quote_detail.quote_id,
        //     formData,
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.getItem("token"),
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     console.log("save edit-quote", res);
        //   })
        //   .catch((err) => {
        //     this.AddToast(err.response.data.message, "Faild", "error_1");
        //     console.log("err", err);
        //   });
      } else {
        console.log("else", this.v$);
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },

    route() {
      this.$router.push("/p-commission");
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
<style scoped>
.topTable {
  width: 33%;
}

.about-commission ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
  margin: 0;
}

.about-commission .ull p {
  margin-bottom: 0;
}

.about-commission ul li {
  list-style: disc;
}

.about-commission p,
.about-commission ul li {
  font-size: 15px;
  font-weight: 500;
}

.both table tr td:first-child {
  font-weight: 600;
  color: #3e3e3e;
}
</style>
