<template>
  <!--**********************************
  Main wrapper start
***********************************-->
  <div id="main-wrapper">

    <!--**********************************
      Content body start
  ***********************************-->
    <div class="content-body pt-md-3 pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">
            {{ lan.admin[12].Accounts[0].Create_new_account }}
          </h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div
                class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
              ></div>

              <div class="card-body p-4">
                <div>
                  <div class="row">
                    <div class="col-md-2 col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Account_Type
                        }}<span>*</span
                        ><span style="color: red">{{
                          v$.type.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <select
                        v-model="state.type"
                        :class="v$.type.$error ? 'input-error' : ''"
                      >
                        <option value="" disabled selected>
                          Please select one
                        </option>
                        <option value="Partner">Partner</option>
                        <option value="Customer">Customer</option>
                        <!-- <option value="Admin">Admin</option> -->
                      </select>
                    </div>

                    <div class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.default_language
                      }}</label
                      ><br />
                      <select name="" id="" v-model="default_language" :placeholder=" lan.default_language">
                        <option value="en">English</option>
                        <option value="sw">Swedish</option>
                      </select>
                    </div>


                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Company_name
                        }}<span>*</span>
                        <span style="color: red">{{
                          v$.company_name.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.company_name"
                        :placeholder=" lan.admin[13].Account_page[0].Company_name"
                        :class="v$.company_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Venue_name
                      }}</label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="venue_name"
                        :placeholder=" lan.admin[13].Account_page[0].Venue_name"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Address }}
                        <span style="color: red">{{
                          v$.address.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Address"
                        v-model="state.address"
                        :placeholder=" lan.admin[13].Account_page[0].Address"
                        :class="v$.address.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Phone_number }}<span style="color: red">{{
                          v$.phone.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Phone number"
                        v-model="state.phone"
                        :placeholder=" lan.admin[13].Account_page[0].Phone_number"
                        :class="v$.phone.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Corporate_number }}
                        <span style="color: red">{{
                          v$.corporate_number.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Corporate number"
                        v-model="state.corporate_number"
                        :placeholder=" lan.admin[13].Account_page[0].Corporate_number"
                        :class="v$.corporate_number.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Invoice_address }}
                        <span style="color: red">{{
                          v$.invoice_address.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Invoice address"
                        v-model="state.invoice_address"
                        :placeholder=" lan.admin[13].Account_page[0].Invoice_address"
                        :class="v$.invoice_address.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Invoice_e_mail
                        }}<span>{{
                          v$.invoice_email.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="state.invoice_email"
                        :placeholder=" lan.admin[13].Account_page[0].Invoice_e_mail"
                        :class="v$.invoice_email.$error ? 'input-error' : ''"
                      />
                    </div>
                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].email
                        }}<span>*</span
                        ><span style="color: red">{{
                          v$.email.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="state.email"
                        :class="v$.email.$error ? 'input-error' : ''"
                        :placeholder=" lan.admin[13].Account_page[0].email"
                      />
                    </div>

                    <div class="col-md-4 col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].Account_owner
                        }}<span>*</span
                        ><span style="color: red">{{
                          v$.account_owner.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <select
                        v-model="state.account_owner"
                        :class="v$.account_owner.$error ? 'input-error' : ''"
                      >
                        <option value="" disabled selected>
                          Please select one
                        </option>
                        <option
                          v-for="data in accountOwners"
                          :value="data.id"
                          :key="data.id"
                        >
                          {{ data.first_name }} {{ data.last_name }}
                        </option>
                      </select>
                    </div>
                    <div v-if="state.type != 'Customer'" class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Payment_terms
                      }}</label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="payment_terms"
                        :placeholder=" lan.admin[13].Account_page[0].Payment_terms"
                      />
                    </div>
                    
                    <div v-if="state.type != 'Customer'" class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Commission_type
                      }}</label
                      ><br />
                      <select name="" id="" v-model="commission_model" :placeholder=" lan.admin[13].Account_page[0].Commission_type">
                        <option value="Single commission model">Single commission model</option>
                        <option value="Split commission model">Split commission model</option>
                      </select>
                    </div>

                    <!-- this commission field are work for single and split both -->
                    <div v-if="state.type != 'Customer'" class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Commission_persent
                      }}</label
                      >
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="commission_model != 'Split commission model' ?
                          lan.admin[13].Account_page[0].Commission_accommodation_conference_meals : 
                          lan.admin[13].Account_page[0].Commission_accommodation_and_conference
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span><br />
                      <input
                        type="text"
                        name="text"
                        v-model="commission"
                        :placeholder=" lan.admin[13].Account_page[0].Commission_persent"
                      />
                    </div>

                    <div v-show="state.type != 'Customer' && commission_model == 'Split commission model'" class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Commission_meals
                      }}</label
                      >
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.admin[13].Account_page[0].Commission_on_meals
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span><br />
                      <input
                        type="text"
                        name="text"
                        v-model="meals_commission"
                        :placeholder=" lan.admin[13].Account_page[0].Commission_persent"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-8 input adjust-map">
                      <div class="row align-items-end">
                        <div class="col-lg-9 col-6">
                      <label>{{ lan.admin[13].Account_page[0].Google_map_location }}</label>
                      <br />
                      <vue-google-autocomplete
                        id="map"
                        :placeholder=" lan.admin[13].Account_page[0].Type_Address"
                        v-on:placechanged="getAddressData"
                      >
                      </vue-google-autocomplete>
                      </div>

                     <div class="col-lg-3 col-6">
                      <button class="filter-btn ml-0 mb-0" data-toggle="modal" data-target="#mapPreview" >
                        {{ lan.admin[13].Account_page[0].View_Map }}
                      </button>
                     </div>
                    </div>
                   </div>

                   
                   <div class="col-sm-4 input primary-contact">
                    <label>{{
                      lan.admin[13].Account_page[0].Primary_contact
                    }}</label
                    ><br />
                    <div class="d-flex">
                      <model-list-select v-if="contactsDetailsFilter" :list="contactsDetailsFilter"
                        style="height: 38px; border: 1px solid #ccc; border-radius: 2px;"
                        v-model="primary_contact"
                        option-value="id"
                        :custom-text="getName"
                        placeholder="Please select one"
                        >
                      </model-list-select>
                      <!-- <select v-model="primary_contact">
                        <option value="" disabled selected>
                          Please select one
                        </option>
                        <option
                          v-for="data in contactsDetailsFilter"
                          :value="data.id"
                          :key="data.id"
                        >
                          {{ data.first_name }} {{ data.last_name }}
                        </option>
                      </select> -->
                      <button
                        class="search-btn-new filter-btn mb-0 p-0"
                        data-toggle="modal"
                        data-target="#exampleModal22"
                      >
                        {{ lan.admin[13].Account_page[0].Create_new_contact }}
                      </button>
                    </div>
                  </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Main_image
                      }}</label
                      ><br />
                      <input
                        type="file"
                        name="file"
                        @change="onFileChange"
                        placeholder="Main image"
                        class="fileInput"
                      />
                    </div>

                    <div v-if="state.type != 'Customer'" class="col-sm-4 input">
                      <label>{{
                        lan.admin[13].Account_page[0].Add_image
                      }}</label
                      ><br />
                      <input
                        type="file"
                        id="files"
                        @change="onFileChange2"
                        name="files"
                        multiple=""
                        class="fileInput"
                      />
                    </div>

                    <!-- <button
                          class="search-btn-new col-sm-2 input "
                          data-toggle="modal" data-target="#exampleModaMap"
                        >
                          View map
                    </button> -->
                  </div>

                  <div v-if="state.type != 'Customer'">
                    <div style="display: flex">
                      <div class="mr-3">
                        <div v-if="preview_image">
                          <span for="" class="pb-4">{{ lan.admin[13].Account_page[0].Main_image}}</span>
                          <div class="column">
                            <img :src="preview_image" class="img-flow my-0" />

                            <div
                              class="overlay row justify-content-center align-items-center mx-0"
                            >
                              <div
                                class="text col-6 d-flex justify-content-center align-items-center"
                                @click="fullImageGallery(preview_image)"
                                data-toggle="modal"
                                data-target="#exampleModal5"
                                style="height: 100%"
                              >
                                <p class="mb-0 fw-bold">{{ lan.admin[13].Account_page[0].View}}</p>
                              </div>
                              <div
                                class="delete col-6 d-flex justify-content-center align-items-center"
                                style="height: 100%"
                              >
                                <button
                                  @click="preview_image = null"
                                  class="icon"
                                >
                                  <i class="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="w-new ml-3"
                        style="overflow: hidden"
                        v-if="
                        (preview_other_images &&
                          preview_other_images.length) ||
                        (other_images && other_images.length)
                      "
                      >
                        <span for="">{{ lan.admin[13].Account_page[0].More_Images}}</span>
                        <div
                          class="moreIMges"
                        >
                          <div
                            v-if="preview_other_images"
                            v-for="(image, index) in preview_other_images"
                            :key="index"
                            style="margin-right: 15px"
                            class="column"
                          >
                            <img :src="image" class="img-flow my-0" />

                            <div
                              class="overlay row justify-content-center align-items-center mx-0"
                            >
                              <div
                                class="text col-6 d-flex justify-content-center align-items-center"
                                @click="fullImageGallery(image)"
                                data-toggle="modal"
                                data-target="#exampleModal5"
                                style="height: 100%"
                              >
                                <p class="mb-0 fw-bold">{{ lan.admin[13].Account_page[0].View}}</p>
                              </div>
                              <div
                                class="delete col-6 d-flex justify-content-center align-items-center"
                                style="height: 100%"
                              >
                                <button
                                  @click="deleteImage(index)"
                                  class="icon"
                                >
                                  <i class="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            v-for="(image, indexx) in other_images"
                            :key="indexx"
                            style="margin-right: 15px"
                          >
                            <img :src="image" class="img-flow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="state.type != 'Customer'" class="input row">
                    <div class="col-12">
                      <div class="info mt-4">
                        <label>{{ lan.partners[6].Profile[0].Title }}</label>
                        <span
                          class="d-inline-block ml-3"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          :title="lan.partners[6].Profile[0].Title_info"
                        >
                          <i class="fa-regular fa-circle-question pointer"></i>
                        </span>
                      </div>
                      <textarea
                        name=""
                        id=""
                        cols="20"
                        rows="5"
                        class="my-2"
                        v-model="title"
                        spellcheck="false"
                        :placeholder="lan.partners[6].Profile[0].Type_somthing_for_Title"
                      ></textarea>
                    </div>

                    <div class="col-12 description-text my-2" style="min-height: 100px">
                      <div class="info">
                        <label>{{ lan.partners[6].Profile[0].Description }}</label>
                        <span
                          class="d-inline-block ml-3"
                          tabindex="0"
                          data-bs-toggle="tooltip"
                          :title="lan.partners[6].Profile[0].Description_info"
                        >
                          <i class="fa-regular fa-circle-question pointer"></i>
                        </span>
                      </div>
                      <div class="styleCkEditor">
                      <ckeditor
                        :editor="editor"
                        v-model="description"
                        :config="editorConfig"
                        :placeholder="lan.partners[6].Profile[0].Type_somthing_for_Description"
                      >
                      </ckeditor>
                      </div>
                    </div>
                  </div>

                  <div  v-if="state.type != 'Customer'" class="input row">
                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_1 }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_1_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      v-if="state.type != 'Customer'"
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      v-model="predefined_comment1"
                      spellcheck="false"
                    ></textarea>
                   </div>

                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_2 }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_2_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      v-if="state.type != 'Customer'"
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      v-model="predefined_comment2"
                      spellcheck="false"
                    ></textarea>
                  </div>
                 </div>

                  <div  v-if="state.type != 'Customer'" class="input row">
                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_3 }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_3_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      v-model="predefined_comment3"
                      spellcheck="false"
                    ></textarea>
                   </div>

                   <div class="col-md-6">
                    <div class="info">
                      <label>{{lan.partners[6].Profile[0].Predefinied_comment_4}}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_4_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      spellcheck="false"
                      v-model="predefined_comment4"
                    ></textarea>
                   </div>
                  </div>


                  <div class="button d-flex justify-content-end mt-4">
                    <button class="search-btn" @click="submit">
                      <div class="lds-ring" v-show="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-show="loadingS">{{
                        lan.admin[13].Account_page[0].Save
                      }}</span>
                    </button>
                    <button class="filter-btn" v-on:click="route()">
                      {{ lan.admin[13].Account_page[0].Cancel }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddConatact @event="getContact" :setRole="state.type" />

    <!-- Modal -->
    <PreviewImageModal :expandedImage="expandedImage" />
    <ViewMapLoctions :mapLocation="google_map_location" />

    <!--**********************************
      Content body end
  ***********************************-->

    <!--**********************************
      Footer start
  ***********************************-->
    <div class="footer">
      <div class="copyright">
        <p>Copyright © 2023 &amp;</p>
      </div>
    </div>
    <!--**********************************
      Footer end
  ***********************************-->
    <!--**********************************
     Support ticket button start
  ***********************************-->

    <!--**********************************
     Support ticket button end
  ***********************************-->
  </div>
  <!--**********************************
  Main wrapper end
***********************************-->
</template>

<script>
import axios from "axios";
import AddConatact from "../../../components/addContact";
import PreviewImageModal from "../../../components/previewImageModal";
import ViewMapLoctions from "../../../components/mapPreview";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Add Account",
  components: {
    VueGoogleAutocomplete,
    AddConatact,
    PreviewImageModal,
    ViewMapLoctions,
    ModelListSelect
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      company_name: "",
      type: "",
      email: "",
      address: "",
      phone: "",
      corporate_number: "",
      invoice_address: "",
      invoice_email: "",
      account_owner: "",
    });

    const rules = computed(() => {
      return {
        company_name: { required },
        type: { required },
        email: { required, email },
        address: {},
        phone: { },
        corporate_number: {},
        invoice_address: {},
        invoice_email: {},
        account_owner: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },

  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
        // plugins: [Alignment], // Include the alignment plugin
        // toolbar: [
        //   "bold",
        //   "italic",
        //   "alignment:left",
        //   "alignment:right",
        //   "alignment:center",
        //   "alignment:justify", // Add alignment options to the toolbar
        //   "numberedList",
        //   "bulletedList",
        //   "blockQuote",
        //   "undo",
        //   "redo"
        // ],
      },
      is_outside_partner: 0,
      venue_name: "",
      commission: "10",
      meals_commission: "",
      commission_model: "Single commission model",
      payment_terms: "",
      default_language: "sw",
      google_map_location: {},
      image: "",
      other_images: [],
      preview_image: "",
      preview_other_images: [],
      description: "",
      title: "",
      primary_contact: "",
      predefined_comment1: "",
      predefined_comment2: "",
      predefined_comment3: "",
      predefined_comment4: "",

      accountOwners: "",

      contactsDetails: [],
      address: "",

      new_contact_details: "",
      check_new_contact: false,

      loading: false,
      loadingS: true,

      hideVenue: true,
      hideCommission: true,
      hidePaymentTerms: true,
      hideMainImage: true,
      hideAddImage: true,
      hideTextArea: true,

      expandedImage: null,
      map:null
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },

    contactsDetailsFilter() {
      // console.log("this.contactsDetails", this.contactsDetails);
      var data = [];
      // var self = this;
      if (this.state.type != "" && this.contactsDetails.length) {
        var type =
          this.state.type == "Partner"
            ? 2
            : this.state.type == "Customer"
            ? 3
            : 1;
        this.contactsDetails.forEach((element) => {
          if (element.roles == type) data.push(element);
        });
      } else {
        data = this.contactsDetails;
      }
      return data;
    },
  },
  // watch:{
  //   google_map_location(newValue,oldValue){
  //     console.log('new',newValue);
  //     console.log('oldValue',oldValue);
  //     var self = this;

  //     self.map = new google.maps.Map(document.getElementById("map"), {
  //         center: { lat: newValue.latitude, lng: newValue.longitude },
  //         zoom: 8,
  //       });
  //     var service = new google.maps.places.PlacesService(self.map);
  //       service.getDetails(
  //         {
  //           placeId: newValue.place_id,
  //         },
  //         function (place, status) {
  //           if (status === google.maps.places.PlacesServiceStatus.OK) {
  //             var marker = new google.maps.Marker({
  //               position: place.geometry.location,
  //               map: self.map,
  //             });
  //             marker.addListener("click", function () {
  //               self.openMap(place.geometry.location);
  //             });
  //             // self.value = false;
  //           }
  //         }
  //       );
  //   }
  // },
  beforeMount() {
    // for top load page
    window.scrollTo(0, 0);
  },
  mounted() {

    // ClassicEditor.create(this.$refs.editor, {
    //   toolbar: {
    //     items: [
    //       'heading',
    //       '|',
    //       'bold',
    //       'italic',
    //       'link',
    //       '|',
    //       'alignment', // Add the alignment option to the toolbar
    //     ]
    //   },
    //   language: 'en',
    //   alignment: {
    //     options: ['left', 'center', 'right'], // Set the available alignment options
    //     toolbar: ['left', 'center', 'right'] // Set the alignment options in the toolbar
    //   }
    // }).then(editor => {
    //   this.editor = editor;
    //   editor.model.document.on('change:data', () => {
    //     this.description = editor.getData();
    //   });
    // }).catch(error => {
    //   console.error(error);
    // });



    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
    this.getContact();

    //************** Account owner details
    axios
      .get(axios.defaults.baseURL + "get-account-owners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountOwners = res.data.data;
        console.log("account owners", this.accountOwners);
      })
      .catch((err) => {
        console.log("err account owner", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    getName(item) {
        return `${item.first_name} ${item.last_name}`
    },
    fullImageGallery(image) {
      console.log("image h-", image);
      this.expandedImage = image;
    },
    closeImageGallery() {
      this.expandedImage = null;
    },
    deleteImage(index) {
      this.preview_other_images.splice(index, 1);
      this.other_images.splice(index, 1);
    },
    getContact(data) {
      this.check_new_contact = data == undefined ? false : true;
      this.new_contact_details = data;
      console.log("getContact", data);
      axios
        .get(axios.defaults.baseURL + "contacts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contactsDetails.length = 0;
          if (this.check_new_contact) {
            this.primary_contact = data.id;
          }
          res.data.data.forEach((element) => {
            if (element.roles != 1) {
              this.contactsDetails.push(element);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    onFileChange(event) {
      const maxAllowedSize = 30 * 1024 * 1024;
      console.log('event',event);
      var size = event.target.files[0].size;
      console.log(size, maxAllowedSize);
      if (size > maxAllowedSize) {
        this.AddToast(
          this.lan.The_image_should_be_less_than_30MB,
          "Information",
          "info_1"
        );
      } else {
        this.preview_image = URL.createObjectURL(event.target.files[0]);
        this.image = event.target.files[0];
      }
      //   }
    },
    onFileChange2(event) {
      const maxAllowedSize = 30 * 1024 * 1024;
      var add = 0;

      for (var i = 0; i < event.target.files.length; i++) {
        var size = event.target.files[i].size;
        if (size > maxAllowedSize) {
          this.AddToast(
            this.lan.The_image_should_be_less_than_30MB,
            "Information",
            "info_1"
          );
        } else {
          add++;
        }
      }
      console.log("add", add);
      console.log(" event.target.files.length", event.target.files.length);
      if (event.target.files.length == add) {
        for (var i = 0; i < event.target.files.length; i++) {
          this.other_images.push(event.target.files[i]);
          this.preview_other_images.push(
            URL.createObjectURL(event.target.files[i])
          );
        }
      }
    },

    submit() {
      this.v$.company_name.$touch();
      this.v$.type.$touch();
      // this.v$.address.$touch();
      this.v$.phone.$touch();
      this.state.type == 'Partner' ? this.v$.email.$touch() : this.state.email = ''
      this.v$.account_owner.$touch();

      if (
        !this.v$.company_name.$invalid &&
        !this.v$.type.$invalid &&
        !this.v$.phone.$invalid &&
        !this.v$.account_owner.$invalid && (this.state.type == 'Partner' ? !this.v$.email.$invalid : this.v$.email.$invalid) 
      ) {
        this.loading = true;
        this.loadingS = false;

        var formData = new FormData();
        formData.append("name", this.state.company_name);
        formData.append("venue_name", this.venue_name);
        formData.append("email", this.state.email);
        formData.append("type", this.state.type);
        formData.append("address", this.state.address);
        formData.append("phone", this.state.phone);
        formData.append("corporate_number", this.state.corporate_number);
        formData.append("invoice_address", this.state.invoice_address);
        formData.append("invoice_email", this.state.invoice_email);
        formData.append("account_owner", this.state.account_owner);
        formData.append("commission", this.commission);
        formData.append("meals_commission", this.meals_commission);
        formData.append("commission_model", this.commission_model);
        formData.append("payment_terms", this.payment_terms);
        formData.append("default_language", this.default_language);
        formData.append("profile_picture", '');
        formData.append(
          "google_map_location",
          JSON.stringify(this.google_map_location)
        );
        if (this.image != "") {
          formData.append("image", this.image);
        }
        for (var i = 0; i < this.other_images.length; i++) {
          formData.append("other_images[]", this.other_images[i]);
        }
        formData.append("is_outside_partner", 0);
        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("primary_contact", this.primary_contact);
        formData.append("predefined_comment1", this.predefined_comment1);
        formData.append("predefined_comment2", this.predefined_comment2);
        formData.append("predefined_comment3", this.predefined_comment3);
        formData.append("predefined_comment4", this.predefined_comment4);

        axios
          .post(axios.defaults.baseURL + "accounts", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res account-", res);

            if (this.check_new_contact) {
              var formData = new FormData();
              formData.append(
                "first_name",
                this.new_contact_details.first_name
              );
              formData.append("last_name", this.new_contact_details.last_name);
              formData.append("email", this.new_contact_details.email);
              formData.append("roles", this.new_contact_details.roles);
              formData.append("phone", this.new_contact_details.phone);
              formData.append("company", res.data.data.account_id);
              formData.append(
                "mobile_phone",
                this.new_contact_details.mobile_phone
              );
              formData.append("address", this.new_contact_details.address);
              formData.append("password", "12345678");
              formData.append("confirm_password", "12345678");
              formData.append("note", "");
              formData.append("task", "");

              axios
                .post(
                  axios.defaults.baseURL +
                    "edit-contact/" +
                    this.new_contact_details.id,
                  formData,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then((res) => {
                  console.log("res -contact-", res);

                  (this.loading = false),
                    (this.loadingS = true),
                    this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
                  this.$router.push({ path: "/accounts" });
                })
                .catch((err) => {
                  (this.loading = false),
                    (this.loadingS = true),
                    console.log("err", err);
                    this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
                });
            } else {
              (this.loading = false),
                (this.loadingS = true),
                this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
              this.$router.push({ path: "/accounts" });
            }
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
              this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },

    route() {
      this.$router.push("/accounts");
    },
    getAddressData(addressData, placeResultData, id) {
      this.google_map_location = {
        administrative_area_level_1: addressData.administrative_area_level_1,
        administrative_area_level_2: addressData.administrative_area_level_2,
        country: addressData.country,
        route: addressData.route,
        postal_code: addressData.postal_code,
        longitude: addressData.longitude,
        latitude: addressData.latitude,
        place_id: placeResultData.place_id,
        url: placeResultData.url,
        formatted_address: placeResultData.formatted_address,
      };
      console.log('addressData',addressData);
      console.log('placeResultData',placeResultData);
      console.log('is',id);
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<style scoped>
.init-new-02 {
  width: 60%;
}

.init-new-02 {
  width: 60%;
}
.mrgn {
  margin-left: 0px !important;
}
.wdt {
  width: 32%;
}
.column {
  position: relative;
}

.column .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.column:hover .overlay {
  opacity: 1;
}
.column .overlay .text {
  background-color: rgba(0, 0, 0, 0.519);
  color: white;
}
.column .overlay .icon {
  width: 40px;
  height: 40px;
  border: none;
}
.column .overlay .delete {
  background-color: rgba(0, 0, 0, 0.347);
}
.column .overlay .icon i {
  font-size: 16px;
}
.column img {
  cursor: pointer;
}

/* Closable button inside the image */
.closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}
.container_img {
  position: relative;
  /* display: none; */
}
.column img:hover {
  opacity: 1;
}
.adjust-map .filter-btn, .primary-contact .filter-btn{
  height: auto;

}
@media screen and (max-width: 576px) {
  .adjust-map .filter-btn, .primary-contact .filter-btn{
    padding: 9px 6px;
  }
  
}
</style>
