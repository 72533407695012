<template>
  <!--**********************************
            Main wrapper start
        ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
      Content body start
  ***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">
            {{
              status == "New"
                ? lan.partners[2].quote_page[0].Quote_to_respond_to 
                : status == "Submitted"
                ? lan.partners[2].quote_page[0].Quote_Waiting_for_acceptance 
                : status == "Accepted"
                ? lan.partners[2].quote_page[0].Quote_Accepted
                : ""
            }}
          </h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header pb-2 d-block flex-wrap border-0">
                <div class="mb-3 mt-4">
                  <div v-if="quote_detail && status != 'New' && status != 'Saved'" class="msg-highlight d-inline-block p-3 my-2">
                      <h6 class="mb-0">{{ lan.partners[2].quote_page[0].This_Quote_is_already_submitted }}</h6>
                  </div>
                  <h4 class="fs-20 text-black">
                    {{
                      lan.partners[1].quote[0]
                        .Contact_person_at_Konferensbokarna
                    }}
                  </h4>
                </div>

              <div class="table-responsive">
                <div class="row justify-content-between mb-5">
                  <div class="col-md-8 mb-md-0 mb-3">
                    <table
                    class="bg-info-hover tr-rounded order-tbl mb-0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          {{ lan.partners[2].quote_page[0].Our_contact_person }}
                        </td>
                        <td>
                          {{
                            contact_detail.first_name +
                            " " +
                            contact_detail.last_name
                          }}
                        </td>
                      </tr>
  
                      <tr>
                        <td>{{ lan.partners[2].quote_page[0].Phone }}</td>
                        <td>{{ contact_detail.phone }}</td>
                      </tr>
  
                      <tr>
                        <td>{{ lan.partners[2].quote_page[0].E_mail }}</td>
                        <td>{{ contact_detail.email }}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>


                  <!-- <div class="col-md-4">
                    <div v-html="renderDynamicHTML"></div>
                  </div> -->


                  <div v-if="status == 'Denied'" class="col-md-4">
                    <div class="yellowish-box p-3" style="background-color: rgb(251, 251, 138); height: 100%;">
                      <h4 class="d-block">{{ lan.partners[2].quote_page[0].Quote_Denied }}</h4>
                      <p class="mb-0">
                        {{ lost_reason }}
                      </p>
                    </div>
                  </div>
                  <div v-else-if="isOpportunity_Won && customer_account_detail_name && (quote_detail.booked == '1' || quote_detail.booked == 1)" class="col-md-4">
                    <div class="yellowish-box p-3" style="background-color: rgb(155, 238, 154); height: 100%;">
                      <h4 class="d-block">{{ lan.partners[2].quote_page[0].Quote_Booked }}</h4>
                      <h6 class="mb-0">
                        {{ customer_account_detail_name[0].name }}
                      </h6>
                    </div>
                  </div>
                  <div v-else-if="isOpportunity_Won && customer_account_detail_name && (quote_detail.booked == '0' || quote_detail.booked == 0)" class="col-md-4">
                    <div class="yellowish-box p-3" style="background-color: rgb(238, 165, 154); height: 100%;">
                      <h4 class="d-block">{{ lan.partners[2].quote_page[0].Quote_Booked_diffrent_venue }}</h4>
                      <h6 class="mb-0">
                        {{ customer_account_detail_name[0].name }}
                      </h6>
                    </div>
                  </div>
                  <div v-else-if="status == 'Preliminary Booking'" class="col-md-4">
                    <div class="yellowish-box p-3" style="background-color: rgb(238, 165, 154); height: 100%;">
                      <h4 class="d-block">{{ lan.partners[2].quote_page[0].Admin_send_you_mail_to_preliminary_booking }}</h4>
                      <h6 class="mb-0">
                        <button class="search-btn pt-2" data-toggle="modal" @click="openPreliminaryBooking()">{{ lan.Status.Respond_to_prel_booking }}</button>
                      </h6>
                    </div>
                  </div>
                  <div v-else-if="status == 'Preliminary Booked'" class="col-md-4">
                    <div class="yellowish-box p-3" style="background-color: rgb(156, 243, 145,1); height: 100%;">
                      <h4 class="d-block">{{ status == 'Preliminary Booked' ? lan.partners[2].quote_page[0].Preliminary_booked : status}}</h4>
                      <button class="search-btn pt-2" data-toggle="modal" @click="openPreliminaryBooking()">{{lan.Open}}</button>
                    </div>
                  </div>

                </div>
              </div>

                <div class="mb-3 mt-4">
                  <h4 class="fs-20 text-black">
                    {{ lan.partners[1].quote[0].Quote_request }}
                  </h4>
                </div>

              <div id="youResponse" class="table-responsive">
                <table class="bg-info-hover tr-rounded order-tbl">
                  <tbody>
                    <tr>
                      <td>{{ lan.partners[2].quote_page[0].Our_customer }}</td>
                      <td v-if="customer_account_detail_name != ''">
                        {{ customer_account_detail_name[0].name }}
                      </td>
                      <td v-else></td>

                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <tr>
                      <td>{{ lan.partners[2].quote_page[0].Date }}</td>
                      <td>{{ opportunity_detail.date }}</td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <tr>
                      <td>
                        {{
                          lan.partners[2].quote_page[0].Number_of_participants
                        }}
                      </td>
                      <td>{{ opportunity_detail.number_of_participants }}</td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <tr>
                      <td>{{ lan.partners[2].quote_page[0].Type_Of_Conference }}</td>
                      <td>
                        {{ opportunity_detail.type_of_conference == 'Day conference' ? lan.partners[2].quote_page[0].Day_conference :  lan.partners[2].quote_page[0].Conference_with_overnight_stay}}
                      </td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <tr
                      v-if="
                        opportunity_detail.type_of_conference ==
                        'Conference with overnight stay'
                      "
                    >
                      <td>{{ lan.partners[2].quote_page[0].Accomodations }}</td>
                      <td>
                        {{
                          lan.partners[2].quote_page[0].Number_of_Single_rooms
                        }} 
                        <strong style="font-size: 15px;">
                          <!-- {{
                            opportunity_detail.no_of_single_rooms != ""
                            ? opportunity_detail.no_of_single_rooms
                            : opportunity_detail.number_of_participants
                          }} -->
                          {{
                            opportunity_detail.no_of_single_rooms
                          }}
                          </strong>
                      </td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''">
                        {{
                          lan.partners[2].quote_page[0]
                            .Number_of_shared_double_rooms
                        }} 
                        <strong style="font-size: 15px;">
                          {{
                            opportunity_detail.no_of_people_in_shared_double_rooms !=
                            ""
                            ? opportunity_detail.no_of_people_in_shared_double_rooms
                            : 0
                          }}
                          </strong>
                        
                      </td>
                    </tr>

                    <tr>
                      <td>{{ lan.partners[2].quote_page[0].Arrival_time }}</td>
                      <td>
                        {{ arrivalTimeFunc(opportunity_detail.arrival_time) }}
                      </td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <tr>
                      <td>
                        {{ lan.partners[2].quote_page[0].Departure_time }}
                      </td>
                      <td>
                        {{
                          departureTimeFunc(opportunity_detail.departure_time)
                        }}
                      </td>
                      <td v-if="opportunity_detail.no_of_people_in_shared_double_rooms != ''"></td>
                    </tr>

                    <!-- <tr>
											<td>Requested conference room</td>
											<td>INFORMATION FROM OPPORTUNITY</td>
											<td></td>
										</tr> -->
                  </tbody>
                </table>
              </div>

              <div v-if="opportunity_detail.free_text_message != ''" class="row ml-md-0 ml-1">
                <textarea
                  cols="20"
                  rows="5"
                  class="col-md-5 col-11 mt-2"
                  v-model="opportunity_detail.free_text_message"
                  :placeholder="lan.partners[2].quote_page[0].Comments"
                  disabled
                ></textarea>
              </div>

              <div class="mb-3 mt-4">
                <h4 class="fs-20 text-black">
                  {{ lan.partners[1].quote[0].Your_response }}
                </h4>
              </div>
              <span>{{ lan.partners[2].quote_page[0].Click_View_below_to_see_more_details_regarding_this_quote }}</span>

            <div class="row">
             <div class="col-xl-9">
              <div class="table-responsive">
                <table class="mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="radio"
                          id="1"
                          name="response"
                          value="Yes"
                          v-model="response"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        />
                        &nbsp;
                        <label for="1">{{
                          lan.partners[2].quote_page[0].Yes
                        }}</label>
                        <br />
                        <input
                        v-if="
                          opportunity_detail.type_of_conference ==
                          'Conference with overnight stay'
                        "
                          type="radio"
                          id="2"
                          name="response"
                          value="Yes, but only if some participants share double room"
                          v-model="response"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        />
                        &nbsp;<label
                        v-if="
                          opportunity_detail.type_of_conference ==
                          'Conference with overnight stay'
                        "
                          for="2"
                          style="color: #b3b3b3; font-style: italic"
                          >{{
                            lan.partners[2].quote_page[0]
                              .Yes_but_only_if_some_participants_share_double_room
                          }}</label>
                      </td>

                      <td>
                        <input
                          type="radio"
                          id="3"
                          name="response"
                          value="No"
                          v-model="response"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        />
                        &nbsp;
                        <label for="3">{{
                          lan.partners[2].quote_page[0].No
                        }}</label>
                        <br/>
                        <input
                          type="radio"
                          id="4"
                          name="response"
                          value="No, because of a preliminary booking"
                          v-model="response"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        />
                        &nbsp;<label
                          for="4"
                          style="color: #b3b3b3; font-style: italic"
                          >{{
                            lan.partners[2].quote_page[0]
                              .No_because_of_a_preliminary_booking
                          }}</label
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
             </div>
            </div>

                <div id="afterSubmit" class="card-body tab-content p-0">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive" style="overflow: hidden">
                      <div class="mt-md-0 mt-3">
                       <div class="row ml-md-0 ml-1">
                        <textarea
                          cols="20"
                          rows="5"
                          class="col-md-5 col-11 mt-2"
                          v-model="comments"
                          :placeholder="lan.partners[2].quote_page[0].CommentsToKonferensbokarna"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        ></textarea>
                       </div>

                        <div class="d-flex ml-md-0 ml-1 mt-2">
                          <button
                          v-if="status != 'Denied' && !isOpportunity_Won"
                            class="search-btn"
                            @click="(response == 'No' || response == 'No, because of a preliminary booking') ? submit() : goToView()"
                            :disabled="
                              (status == 'New' || status == 'Saved')
                                ? false
                                : true
                            "
                          >
                            <div
                              class="lds-ring"
                              v-show="loadingSaveNewQuoteLoader1"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSaveNewQuoteText1">{{
                              !response ? lan.partners[2].quote_page[0].View : (response == 'Yes' || response == 'Yes, but only if some participants share double room') ? lan.partners[2].quote_page[0].Continue : lan.partners[2].quote_page[0].Submit
                            }}</span>
                          </button>
                          <router-link to="/p-quotes"
                            ><button class="filter-btn">
                              {{ lan.partners[2].quote_page[0].Cancel }}
                            </button>
                          </router-link>
                        </div>
                      </div>

                      <div v-show="show_next_div">
                       <div class="row">
                        <div class="col-md-4 mt-4">
                          <label>{{
                            lan.partners[2].quote_page[0].Prices_should_be
                          }}</label
                          ><br />
                          <input
                            type="radio"
                            id="5"
                            name="excl_vat"
                            value="excl vat"
                            v-model="opportunity_detail.vat"
                            disabled
                          />
                          &nbsp;
                          <label for="5">
                            {{ lan.partners[2].quote_page[0].Ex_vat }}
                          </label>
                          &nbsp;
                          <input
                            type="radio"
                            id="6"
                            name="inc_vat"
                            value="incl vat"
                            disabled
                            v-model="opportunity_detail.vat"
                          />
                          &nbsp;
                          <label for="6">
                            {{ lan.partners[2].quote_page[0].Inc_vat }}
                          </label>
                        </div>
                       </div>

                        <div v-if="accomodation_details && accomodation_details.length" class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.partners[2].quote_page[0]
                                .Price_for_accommodations
                            }}
                          </h4>
                        </div>

                        <div v-if="accomodation_details && accomodation_details.length" class="table-responsive">
                          <table class="bg-info-hover tr-rounded order-tbl">
                            <tr>
                              <th>{{ lan.partners[2].quote_page[0].Date }}</th>
                              <th>
                                {{ lan.partners[2].quote_page[0].Product }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Specification
                                }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Requested_number
                                }}
                              </th>
                              <th v-if="response != 'Yes'">
                                {{
                                  lan.partners[2].quote_page[0]
                                    .Numbers_we_can_offer
                                }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Ordinary_price
                                }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0]
                                    .Price_for_this_quote
                                }}
                              </th>
                              <th>
                                {{ lan.partners[2].quote_page[0].Discount }}
                              </th>
                            </tr>

                            <tbody>
                              <!-- <tr
                                v-for="(data, index) in accomodation_details"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td>{{ data.product.product_name }}</td>
                                <td style="white-space: initial; width: 400px">
                                  {{ data.product.product_description }}
                                </td>
                                <td style="font-size: 15px">{{ data.room }}</td>
                                <td v-if="response != 'Yes'">
                                  <input
                                    class="init"
                                    type="number"
                                    v-model="data.room_we_offer"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    v-model="data.ordinary_price"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    v-model="data.price_for_this_quote"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td style="font-size: 15px">
                                  {{ formatNumber(data,'Accomodation') }}
                                </td>
                              </tr> -->
                              <tr
                                v-for="(data, index) in response == '' ? accomodation_details : accomodation_details_with_filtter"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td>{{ data.product.product_name }}</td>
                                <td style="white-space: initial; width: 400px">
                                  {{ data.product.product_description }}
                                </td>
                                <td style="font-size: 15px">{{ data.room }}</td>
                                <td v-if="response != 'Yes'">
                                  <input
                                    class="init"
                                    type="number"
                                    v-model="data.room_we_offer"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    v-model="data.ordinary_price"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    v-model="data.price_for_this_quote"
                                    min="0"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td style="font-size: 15px">
                                  {{ formatNumber(data,'Accomodation') }}
                                  <!-- {{
                                    (discount =
                                      (data.ordinary_price -
                                      data.price_for_this_quote) * (response != 'Yes' ? data.room_we_offer : data.room))
                                  }} -->
                                </td>
                                <!-- <td> {{ total_discount =  total_discount + discount}} </td>
                              <td> {{  discount}} </td> -->
                              </tr>

                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td v-if="response != 'Yes'"></td>
                                <td></td>
                                <td style="font-size: 15px">
                                  {{
                                    lan.partners[2].quote_page[0].Total_Price
                                  }}: <br />{{ accomodation_total_ammount }}
                                </td>
                                <td style="font-size: 15px">
                                  {{
                                    lan.partners[2].quote_page[0]
                                      .Total_Discount
                                  }}: <br />
                                  {{ accomodation_total_discount }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!--how many coursres start-->
                       <div class="table-responsive">
                        <table v-if="accomodation_details_for_dinner_course && accomodation_details.length" class="my-5">
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  lan.partners[2].quote_page[0]
                                    .How_many_courses_are_included_in_the_dinner
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <select
                                  class="select-01 init-01"
                                  v-model="courses_in_dinner"
                                  :class="jsonValidations && courses_in_dinner == '' ? 'input-error' : ''"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                >
                                  <option value="1 courses">1 {{lan.partners[2].quote_page[0].Courses}}</option>
                                  <option value="2 courses">2 {{lan.partners[2].quote_page[0].Courses}}</option>
                                  <option value="3 courses">3 {{lan.partners[2].quote_page[0].Courses}}</option>
                                  <option value="4 courses">4 {{lan.partners[2].quote_page[0].Courses}}</option>
                                </select>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                       </div>
                        <!--how many coursres end-->

                        <div
                          class="mb-3 mt-4"
                          v-if="meals_details && meals_details.length"
                        >
                          <h4 class="fs-20 text-black">
                            {{ lan.partners[2].quote_page[0].Price_for_meals }}
                          </h4>
                        </div>

                        <div
                          v-if="meals_details && meals_details.length"
                          class="table-responsive"
                        >
                          <table class="bg-info-hover tr-rounded order-tbl">
                            <tr>
                              <th>{{ lan.partners[2].quote_page[0].Date }}</th>
                              <th>
                                {{ lan.partners[2].quote_page[0].Product }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Specification
                                }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Requested_number
                                }}
                              </th>
                              <!-- <th>
                                {{
                                  lan.partners[2].quote_page[0]
                                    .Numbers_we_can_offer
                                }}
                              </th> -->
                              <th>
                                {{
                                  lan.partners[2].quote_page[0].Ordinary_price
                                }}
                              </th>
                              <th>
                                {{
                                  lan.partners[2].quote_page[0]
                                    .Price_for_this_quote
                                }}
                              </th>
                              <th>
                                {{ lan.partners[2].quote_page[0].Discount }}
                              </th>
                            </tr>

                            <tbody>
                              <tr
                                v-for="(data, index) in meals_details"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td>{{ data.product.product_name }}</td>
                                <td style="white-space: initial; width: 400px">
                                  {{ data.product.product_description }}
                                </td>
                                <td style="font-size: 15px">{{ data.room }}</td>
                                <!-- <td>
                                  <input
                                    class="init"
                                    type="number"
                                    :disabled="
                                      status == 'Submitted' ||
                                      status == 'Accepted'
                                        ? true
                                        : false
                                    "
                                    v-model="data.room_we_offer"
                                    min="0"
                                  />
                                </td> -->
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                    v-model="data.ordinary_price"
                                    min="0"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="init"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                    v-model="data.price_for_this_quote"
                                    min="0"
                                  />
                                </td>
                                <td style="font-size: 15px">
                                  {{ formatNumber(data,'Meals') }}
                                  <!-- {{
                                    (discount =
                                      (data.ordinary_price -
                                      data.price_for_this_quote) * data.room)
                                  }} -->
                                </td>
                                <!-- <td> {{ total_discount =  total_discount + discount}} </td>
                              <td> {{  discount}} </td> -->
                              </tr>

                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <!-- <td></td> -->
                                <td style="font-size: 15px">
                                  {{
                                    lan.partners[2].quote_page[0].Total_Price
                                  }}
                                  : <br />{{ meals_total_ammount }}
                                </td>
                                <td style="font-size: 15px">
                                  {{
                                    lan.partners[2].quote_page[0].Total_Discount
                                  }}
                                  : <br />
                                  {{ meals_total_discount }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>


                        <!-- **********************Other products start************************ -->
                        <div
                        v-if="
                          other_product_details &&
                          other_product_details.length
                        "
                        class="mb-3 mt-4"
                      >
                        <h4 class="fs-20 text-black">
                          {{ lan.partners[2].quote_page[0].Other_Products }}
                        </h4>
                      </div>

                    <div class="table-responsive">
                      <table
                        v-if="
                          other_product_details &&
                          other_product_details.length
                        "
                        class="bg-info-hover tr-rounded order-tbl"
                      >
                        <tr>
                          <th>{{ lan.partners[2].quote_page[0].Date }}</th>
                          <th>{{ lan.partners[2].quote_page[0].Product }}</th>
                          <th>
                            {{ lan.partners[2].quote_page[0].Specification }}
                          </th>
                          <th>
                            {{
                              lan.partners[2].quote_page[0].Requested_number
                            }}
                          </th>
                          <th>
                            {{ lan.partners[2].quote_page[0].Ordinary_price }}
                          </th>
                          <th>
                            {{
                              lan.partners[2].quote_page[0]
                                .Price_for_this_quote
                            }}
                          </th>
                          <th>
                            {{ lan.partners[2].quote_page[0].Discount }}
                          </th>
                        </tr>

                        <tbody>
                          <tr
                            v-for="(data, index) in other_product_details"
                            :key="index"
                          >
                            <td>{{ data.date }}</td>
                            <td>{{ data.product.product_name }}</td>
                            <td style="white-space: initial">
                              {{ data.product.product_description }}
                            </td>
                            <td style="font-size: 15px">{{ data.room }}</td>
                            <!-- <td>{{ data.room }}</td> -->
                            <!-- <td>
                              <input
                                class="init"
                                type="number"
                                v-model="data.room_we_offer"
                                min="0"
                              />
                            </td> -->
                            <td>
                              <input
                                type="number"
                                class="init"
                                v-model="data.ordinary_price"
                                min="0"
                                :disabled="
                                  status == 'New' || status == 'Saved'
                                    ? false
                                    : true
                                "
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="init"
                                v-model="data.price_for_this_quote"
                                min="0"
                                :disabled="
                                  status == 'New' || status == 'Saved'
                                    ? false
                                    : true
                                "
                              />
                            </td>
                            <td style="font-size: 15px">
                              {{ formatNumber(data,'Other') }}
                              <!-- {{
                                (discount =
                                  (data.ordinary_price -
                                  data.price_for_this_quote))
                              }} -->
                            </td>
                            <!-- <td> {{ total_discount =  total_discount + discount}} </td>
                            <td> {{  discount}} </td> -->
                          </tr>

                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="font-size: 15px">
                              {{ lan.partners[2].quote_page[0].Total_Price }}
                              = {{ other_product_total_ammount }}
                            </td>
                            <td style="font-size: 15px">
                              {{
                                lan.partners[2].quote_page[0].Total_Discount
                              }}
                              =
                              {{ other_product_total_discount }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                      <!-- **********************Other Product end************************ -->


                        <!-- **********************Conference Hall************************ -->
                        <div
                          v-if="
                            conference_room_details &&
                            conference_room_details.length
                          "
                          v-for="(data, index) in conference_room_details"
                          :key="index"
                        >
                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                index == 0
                                  ? lan.partners[2].quote_page[0]
                                      .Main_Conference_Hall
                                  : lan.partners[2].quote_page[0]
                                      .Secondry_confrence_hall
                              }} ({{ checkDate_ConefrenceHall(data.date) }})
                            </h4>
                          </div>

                          <div class="table-responsive">
                            <table class="bg-info-hover tr-rounded order-tbl">
                              <tbody>
                                <!-- <tr>
													<td>Main conference hall</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													
												  </tr> -->

                                <tr>
                                  <th>
                                    {{
                                      lan.partners[2].quote_page[0]
                                        .Customer_requested_size_of_the_room_Seating_type
                                    }}
                                  </th>
                                  <th style="width: 17%; font-size: 15px">
                                    {{ data.request_size }}
                                  </th>
                                  <th style="width: 10%; font-size: 15px">
                                    {{ checkSeating_type_transaltion(data.seating) }}
                                  </th>
                                  <th v-if="data.comment != ''">
                                    {{ lan.partners[2].quote_page[0].Notes }}
                                  </th>
                                  <!-- <td>Price</td> -->
                                </tr>

                                <tr>
                                  <th>
                                    {{
                                      lan.partners[2].quote_page[0]
                                        .The_room_we_offer_accomodate
                                    }}
                                  </th>
                                  <td>
                                    <input
                                      type="text"
                                      class="init"
                                      :placeholder="
                                        lan.partners[2].quote_page[0].Number
                                      "
                                      v-model="data.request_size_we_can_offer"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                    />
                                  </td>
                                  <td>
                                    <select
                                      class="select-01"
                                      v-model="data.seating_we_can_offer"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                    >
                                      <option value="Cinema Seating">
                                        {{lan.partners[2].quote_page[0].Cinema_Seating}}
                                      </option>
                                      <option value="U-shaped table">
                                        {{lan.partners[2].quote_page[0].Ushaped_table}}
                                      </option>
                                      <option value="Boardroom">
                                        {{lan.partners[2].quote_page[0].Boardroom}}
                                      </option>
                                      <option value="School Seating">
                                        {{lan.partners[2].quote_page[0].School_Seating}}
                                      </option>
                                      <option value="Islands">{{lan.partners[2].quote_page[0].Islands}}</option>
                                      <option value="Don't know at the moment">
                                        {{lan.partners[2].quote_page[0].Dont_know_at_the_moment}}
                                      </option>
                                    </select>
                                  </td>
                                  <td v-if="data.comment != ''">
                                    <textarea
                                      id="textarea"
                                      class="init"                                   
                                      type="text"
                                      v-model="data.comment"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                      readonly
                                    ></textarea>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {{ lan.partners[2].quote_page[0].Date }}
                                  </th>
                                  <th>
                                    {{
                                      lan.partners[2].quote_page[0]
                                        .Ordinary_price
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      lan.partners[2].quote_page[0]
                                        .Price_for_this_quote
                                    }}
                                  </th>
                                  <th>
                                    {{ lan.partners[2].quote_page[0].Discount }}
                                  </th>
                                </tr>
                                <tr>
                                  <td>{{ data.date }}</td>
                                  <td>
                                    <input
                                      type="text"
                                      class="init"
                                      :placeholder="
                                        lan.partners[2].quote_page[0]
                                          .Ordinary_price
                                      "
                                      v-model="data.ordinary_price"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="init"
                                      :placeholder="
                                        lan.partners[2].quote_page[0]
                                          .Price_for_this_quote
                                      "
                                      v-model="data.fee_confrence_room"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                    />
                                  </td>
                                  <td style="font-size: 15px">
                                    {{
                                      data.ordinary_price -
                                      data.fee_confrence_room
                                    }}
                                  </td>
                                </tr>

                                <!-- <tr>
                                <td>
                                  Date From Opportunity <br />
                                  (if multiple dates)
                                </td>
                                <td>Fee for conference room</td>
                                <td></td>
                                <td>Ordinary price</td>
                                <td>Discount (CALC based on ord price)</td>
                              </tr> -->

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td style="font-size: 15px">
                                    {{
                                      lan.partners[2].quote_page[0].Total_Price
                                    }}
                                    : <br> {{ data.fee_confrence_room }}
                                  </td>
                                  <td style="font-size: 15px">
                                    {{
                                      lan.partners[2].quote_page[0]
                                        .Total_Discount
                                    }}
                                    : <br>
                                    {{
                                      data.ordinary_price -
                                      data.fee_confrence_room
                                    }}
                                  </td>
                                  <!-- <td></td> -->
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!-- **********************Conference Hall end************************ -->


                        <!-- **********************Additional questions start************************ -->
                        <div
                          class="mb-3 mt-4"
                          v-if="
                            additional_questions && additional_questions.length
                          "
                        >
                          <h4 class="fs-20 text-black">
                            {{
                              lan.partners[2].quote_page[0]
                                .Additional_questions
                            }}
                          </h4>
                        </div>

                        <div
                          class="table-responsive"
                          v-if="
                            additional_questions && additional_questions.length
                          "
                        >
                          <table
                            class="bg-info-hover tr-rounded order-tbl"
                            style="white-space: normal"
                          >
                            <tbody
                              v-for="(data, index) in additional_questions"
                              :key="index"
                            >
                              <tr
                                v-if="data.inputType == 'radio'"
                                class="row px-3"
                              >
                                <td class="col-12 fs"><strong>{{ data.question }}</strong></td>
                                <td class="col-12">
                                  &nbsp;
                                  <input
                                    type="radio"
                                    id="7"
                                    :name="'fav_language' + [index]"
                                    value="Yes"
                                    v-model="data.answer"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                  &nbsp;
                                  <label for="7">{{
                                    lan.partners[2].quote_page[0].Yes
                                  }}</label>

                                  &nbsp;
                                  <input
                                    type="radio"
                                    id="8"
                                    :name="'fav_language' + [index]"
                                    value="No"
                                    v-model="data.answer"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                  &nbsp;
                                  <label for="8">{{
                                    lan.partners[2].quote_page[0].No
                                  }}</label>
                                </td>
                              </tr>

                              <tr
                                v-if="data.inputType == 'text'"
                                class="row px-3"
                              >
                                <td class="col-12 fs"> <strong>{{ data.question }}</strong></td>
                                <td class="col-12">
                                  &nbsp;
                                  <textarea
                                  style="height: auto;"
                                   @input="autoResize()"
                                    type="text"
                                    class="init myTextarea"
                                    v-model="data.answer"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  ></textarea>
                                </td>
                              </tr>

                              <tr
                                v-if="data.inputType == 'dropdown'"
                                class="row px-3"
                              >
                                <td class="col-12 fs"><strong>{{ data.question }}</strong></td>
                                <td class="col-12">
                                  <select
                                    style="width: 100%"
                                    class="select-01"
                                    v-model="data.answer"
                                    @change="selectDropdown(index)"
                                  >
                                    <option value="" disabled selected>
                                      {{lan.partners[2].quote_page[0].You_can_select_Maximum}} {{ data.maxSelect }} {{lan.partners[2].quote_page[0].option_in_the_dropdown}}
                                    </option>
                                    <option
                                      :value="indexx"
                                      v-for="(dataa, indexx) in data.dropdown"
                                      :key="indexx"
                                      :disabled="
                                        status == 'New' || status == 'Saved'
                                          ? false
                                          : true
                                      "
                                    >
                                      {{ dataa.name }}
                                    </option>
                                  </select>
                                </td>
                                <div v-if="data.answertags.length" class="tag-container" style="padding: 0px 15px">
                                    <h5 class="mr-2">{{lan.partners[2].quote_page[0].Selected}}:- </h5>
                                    <span v-for="item in data.answertags" :key="item" class="tag">
                                      {{ item.name }}
                                      <i v-if="status == 'New' || status == 'Saved'" class="fa fa-times" @click="selectDropdownDelete(item,index)" ></i>
                                    </span>
                                </div>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <!-- **********************Additional questions end************************ -->

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.partners[2].quote_page[0]
                                .Additional_information
                            }}
                          </h4>
                        </div>

                        <span class="d-block">{{lan.partners[2].quote_page[0].information_for_customer}}</span>

                       <div class="row align-items-center my-3">
                        <div class="col-sm-5 mb-md-0 mb-2">
                         <select
                          class="select-01 w-100"
                          v-model="select_predefined_text"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                          >
                           <option
                            :value="data"
                            v-for="data in predefined_comment_from_account"
                           >
                            {{ data }}
                          </option>
                         </select>
                        </div>

                       <div class="col-sm-2">
                        <button
                          class="filter-btn mb-0 ml-0"
                          @click="add_predefined_text()"
                          :disabled="
                            status == 'New' || status == 'Saved' ? false : true
                          "
                        >
                          {{ lan.partners[2].quote_page[0].Add_text }}
                        </button>
                      </div>
                     </div>

                        <!-- <br />
                        <span v-if="row_predefined_text1 != ''"
                          >{{ row_predefined_text1 }}<br
                        /></span>
                        <span v-if="row_predefined_text2 != ''"
                          >{{ row_predefined_text2 }}<br
                        /></span>
                        <span v-if="row_predefined_text3 != ''"
                          >{{ row_predefined_text3 }}<br
                        /></span>
                        <span v-if="row_predefined_text4 != ''">{{
                          row_predefined_text4
                        }}</span> -->
                        <!-- <span v-if="text && text.length" v-for="(data) in text">{{ data }}</span> -->

                        <div action=""  class="addInfoRich mt-4">
                          <!-- <textarea
                          @input="autoResize()"
                            cols="20"
                            rows="6"
                            style="width: 100%"
                            class="mt-2 myTextarea"
                            v-model="row_predefined_text_area"
                            :placeholder="
                              lan.partners[2].quote_page[0]
                                .Text_to_add_from_list_above_or_free_written_text_in_this_box
                            "
                            :disabled="
                              status == 'New' || status == 'Saved'
                                ? false
                                : true
                            "
                          ></textarea> -->
                          <h6>{{lan.partners[2].quote_page[0].Text_to_add_from_list_above_or_free_written_text_in_this_box}}</h6>
                          <ckeditor
                          :editor="editor"
                          v-model="row_predefined_text_area"
                          :placeholder="lan.partners[2].quote_page[0].Text_to_add_from_list_above_or_free_written_text_in_this_box"
                          :disabled="
                          status == 'New' || status == 'Saved'
                            ? false
                            : true
                           "
                          >
                          </ckeditor>
                        </div>



                        <div action="" class="mt-4">
                          <div
                            class="d-flex"
                            v-for="data in Add_more_buttons"
                            :key="data"
                            style="width: 82%"
                          >
                            <div class="input">
                              <div class="mb-3">
                                <label>{{
                                  lan.partners[2].quote_page[0].Submitted_file_1
                                }}</label
                                ><br />
                                <div class="init d-flex p-0">
                                  <input
                                    type="file"
                                    name="file"
                                    placeholder="Submitted file 1"
                                    @change="onFileChange2"
                                    :disabled="
                                      status == 'New' || status == 'Saved'
                                        ? false
                                        : true
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="
                              all_attachments &&
                              all_attachments.length
                            "
                            class="d-flex flex-wrap mb-4" style="overflow: auto;"
                          >
                            <div
                              v-for="(
                                image, index
                              ) in all_attachments"
                              :key="index"
                              class="allAttachments mr-2 mb-2"
                            >
                            <div v-if="image.type != 'application/pdf'" class="column">
                              <img
                                :src="!image.preview ? image.file : image.preview"
                                class="img-flow my-0"
                                alt="Nature"
                        
                              />
                              <div class="overlay row justify-content-center align-items-center mx-0">
                                <div class="text col-6 d-flex justify-content-center align-items-center pointer"
                                @click="fullImageGallery(!image.preview ? image.file : image.preview)"
                                data-toggle="modal" data-target="#exampleModal5"
                                 style="height: 100%;">
                                  <p class="mb-0 fw-bold">{{lan.partners[2].quote_page[0].View}}</p>
                                </div>
                                <div class="delete col-6 d-flex justify-content-center align-items-center" style="height: 100%;">
                                  <button @click="deleteImage(index)" class="icon">
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            

                            <div
                            v-if="image.type == 'application/pdf'"
                              class="col-lg-12 pr-md-3 pr-0 mb-md-0 mb-4"
                            >
                            <div class="row justify-content-center align-items-center mb-3">
                              <div class="col-6 pointer" data-toggle="modal" data-target="#exampleModal12" @click="getPdfImage(!image.preview ? image.file : image.preview)">
                                <button type="submit" class="filter-btn mb-0 ml-0 py-2 px-3">{{lan.partners[2].quote_page[0].View_Pdf}}</button>
                              </div>
                            <div class="col-6 d-flex justify-content-end del-icon-pdf">
                              <button @click="deletePdf(index)" class="icon">
                                <i class="fa-solid fa-trash"></i>
                              </button>
                            </div>
                            </div>
                              <embed
                                :src="!image.preview ? image.file : image.preview"
                                type="video/webm"
                                width="100%"
                                style="max-height: 70rem; min-height: 20rem"
                              />
                            </div>


                            <textarea class="mt-1" v-model="image.message" cols="30" rows="3" placeholder="File Information" ></textarea>
                            <!-- {{ image.message }} -->
                            </div>
                          </div>
                        </div>



                        <div action="" class="row mt-3 commission">
                          <div :class="[account_detail.commission_model === 'Split commission model' ? 'col-12 mb-3' : 'col-md-4']">
                            <div class="row">
                              <div :class="['mb-md-0', 'mb-3', 'd-flex', 'align-items-center', 'justify-content-between', 'right-bordr', account_detail.commission_model === 'Split commission model' ? 'col-md-6' : 'col-md-12']">
                                <h5 class="pr-2 mb-0">
                                  {{
                                    account_detail.commission_model != 'Split commission model' ? lan.partners[2].quote_page[0].Normal_commission : lan.partners[2].quote_page[0].commission_accom_confe
                                  }}
                                </h5>
                                <input
                                  type="text"
                                  name="text"
                                  class="init text-center"
                                  style="width: 18%"
                                  :placeholder="
                                    lan.partners[2].quote_page[0]
                                      .Commission_from_Account
                                  "
                                  v-model="account_detail.commission"
                                  readonly
                                />
                            </div>
                            <div v-if="account_detail.commission_model == 'Split commission model'" class="col-md-6 mb-md-0 mb-3 d-flex align-items-center justify-content-between right-bordr">
                                <h5 class="pr-2 mb-0">
                                  {{
                                    lan.partners[2].quote_page[0].commission_of_meals
                                  }}
                                </h5>
                                <input
                                  type="text"
                                  name="text"
                                  class="init text-center"
                                  style="width: 18%"
                                  :placeholder="
                                    lan.partners[2].quote_page[0]
                                      .Commission_from_Account
                                  "
                                  v-model="account_detail.meals_commission"
                                  readonly
                                />
                            </div>
                            </div>
                          </div>
                          

                          <div :class="[account_detail.commission_model === 'Split commission model' ? 'col-12 mb-3' : 'col-md-4']">
                            <div class="row">
                                <div
                                :class="['mb-md-0', 'mb-3', 'd-flex', 'align-items-center', 'justify-content-between', 'right-bordr', account_detail.commission_model === 'Split commission model' ? 'col-md-6' : 'col-md-12']"
                              >
                                <h5 class="pr-2 mb-0">
                                  {{
                                    account_detail.commission_model != 'Split commission model' ? lan.partners[2].quote_page[0].Commission_for_this_deal : lan.partners[2].quote_page[0].Commission_for_this_deal_accom_confe
                                  }}
                                </h5>
                                <input
                                  type="text"
                                  name="text"
                                  class="init text-center"
                                  style="width: 18%"
                                  v-model="commission"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />
                            </div>
                            <div
                            v-if="account_detail.commission_model == 'Split commission model'"
                                class="col-md-6 mb-md-0 mb-3 d-flex align-items-center justify-content-between right-bordr"
                              >
                                <h5 class="pr-2 mb-0">
                                  {{
                                    lan.partners[2].quote_page[0]
                                      .Commission_for_this_deal_meals
                                  }}
                                </h5>
                                <input
                                  type="text"
                                  name="text"
                                  class="init text-center"
                                  style="width: 18%"
                                  v-model="meals_commission"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />
                            </div>
                            </div>
                          </div>

                          <div class="col-md-4 d-flex align-items-center justify-content-between">
                            <h5 class="pr-2 mb-0">
                              {{
                                lan.partners[2].quote_page[0]
                                  .Free_of_charge_cancellation
                              }}
                            </h5>
                            <div class="init p-0 d-flex" :class="jsonValidations && free_of_charge_cancellation.date == '' ? 'input-error' :''" style="width: 40%">
                              <input
                                type="number"
                                name="text"
                                class="init text-center init-new initt"
                                v-model="free_of_charge_cancellation.date"
                                min="0"
                                :disabled="
                                  status == 'New' || status == 'Saved'
                                    ? false
                                    : true
                                "
                              />

                              <select
                                class="select init-new-01 initt"
                                v-model="free_of_charge_cancellation.week"
                                :disabled="
                                  status == 'New' || status == 'Saved'
                                    ? false
                                    : true
                                "
                              >
                                <option value="Week" selected>{{lan.Weeks}}</option>
                                <option value="Month">{{lan.Months}}</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div action="" class="d-flex mt-3"></div>

                        <div class="row">
                          <div class="col-md-8">
                            <div class="table-responsive">
                              <table class="mt-3">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label for="html">{{
                                        lan.partners[2].quote_page[0]
                                          .Do_you_have_contact_directly_with_this_customer
                                      }}</label>
                                    </td>
      
                                    <td>
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="9"
                                        name="one"
                                        value="Yes"
                                        v-model="questions.one"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="9">{{
                                        lan.partners[2].quote_page[0].Yes
                                      }}</label>
      
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="10"
                                        name="one"
                                        value="No"
                                        v-model="questions.one"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="10">{{
                                        lan.partners[2].quote_page[0].No
                                      }}</label>
                                    </td>
                                  </tr>
      
                                  <tr>
                                    <td>
                                      <label for="html">{{
                                        lan.partners[2].quote_page[0]
                                          .Did_you_receive_a_quote_request_from_another_company
                                      }}</label>
                                    </td>
      
                                    <td>
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="11"
                                        name="two"
                                        value="Yes"
                                        v-model="questions.two"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="11">{{
                                        lan.partners[2].quote_page[0].Yes
                                      }}</label>
      
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="12"
                                        name="two"
                                        value="No"
                                        v-model="questions.two"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="12">{{
                                        lan.partners[2].quote_page[0].No
                                      }}</label>
                                    </td>
                                  </tr>
      
                                  <tr v-if="questions.two == 'Yes' || questions.one == 'Yes'">
                                    <td>
                                      <label for="html">{{
                                        lan.partners[2].quote_page[0]
                                          .Did_you_offer_the_same_price
                                      }}</label>
                                    </td>
      
                                    <td>
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="13"
                                        name="three"
                                        value="Yes"
                                        v-model="questions.three"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="13">{{
                                        lan.partners[2].quote_page[0].Yes
                                      }}</label>
      
                                      &nbsp;
                                      <input
                                        type="radio"
                                        id="14"
                                        name="three"
                                        value="No"
                                        v-model="questions.three"
                                        :disabled="
                                          status == 'New' || status == 'Saved'
                                            ? false
                                            : true
                                        "
                                      />
                                      &nbsp;
                                      <label for="14">{{
                                        lan.partners[2].quote_page[0].No
                                      }}</label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                             </div>
                          </div>
                        </div>
                      
                         
                        <div class="row" v-if="questions.two == 'Yes' || questions.one == 'Yes'">
                          <div class="col-md-8">
                            <div action="" class="mt-md-0 mt-3">
                              <textarea
                                @input="autoResize()"
                                cols="20"
                                rows="2"
                                style="width: 100%"
                                class="myTextarea"
                                v-model="additional_comment"
                                :placeholder="lan.Comment_field"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="my-5 d-inline-block" style="border: 0.5px solid #f58a8a;">
                          <h5 class="p-2 mb-0"> {{ lan.partners[2].quote_page[0].Please_dont_make_a_preliminary_booking }} </h5>
                        </div>
                   

                       <div class="table-responsive">
                        <table class="mt-3" :class="jsonValidations && questions.four != 'Yes' ? 'input-error' : ''" style="width: 60%">
                          <tbody>
                            <tr>
                              <td>
                                <label for="html"
                                  >{{
                                    lan.partners[2].quote_page[0]
                                      .Prices_should_be_Have
                                  }}
                                  {{
                                    opportunity_detail.vat == "excl vat"
                                      ? lan.partners[2].quote_page[0].Ex_vat
                                      : lan.partners[2].quote_page[0].Inc_vat
                                  }}
                                  {{
                                    lan.partners[2].quote_page[0]
                                      .Prices_Have_you_responded_accordingly
                                  }}
                                </label>
                              </td>

                              <td>
                                &nbsp;
                                <input
                                  type="radio"
                                  id="15"
                                  name="four"
                                  value="Yes"
                                  v-model="questions.four"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />
                                &nbsp;
                                <label for="15">{{
                                  lan.partners[2].quote_page[0].Yes
                                }}</label>

                                &nbsp;
                                <input
                                  type="radio"
                                  id="16"
                                  name="four"
                                  value="No"
                                  v-model="questions.four"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />
                                &nbsp;
                                <label for="16">{{
                                  lan.partners[2].quote_page[0].No
                                }}</label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- <span v-if="jsonValidations" class="text-danger"><i class="fa-solid fa-triangle-exclamation me-2"></i>Please select one option</span> -->
                       </div>

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.partners[2].quote_page[0]
                                .Responder_information
                            }}
                          </h4>
                        </div>

                        <div class="row">
                          <div class="col-lg-4">
                            <div action="" class="mt-4">
                              <div class="input">
                                <label class="mb-3"
                                  >{{ lan.partners[2].quote_page[0].My_name_is
                                  }}<span>*</span></label
                                ><br />
                                <input
                                  class="mb-3"
                                  type="text"
                                  name="name"
                                  :placeholder="
                                    lan.partners[2].quote_page[0].name
                                  "
                                  :class="v$.submitter_name.$error ? 'input-error' : ''"
                                  v-model="state.submitter_name"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />

                                <label class="mb-3"
                                  >{{
                                    lan.partners[2].quote_page[0].Phone_number
                                  }}<span>*</span></label
                                ><br />
                                <input
                                  class="mb-3"
                                  type="tel"
                                  name="number"
                                  pattern="[0-9]*"
                                  :placeholder="
                                    lan.partners[2].quote_page[0].Phone
                                  "
                                  :class="v$.submitter_phone.$error ? 'input-error' : ''"
                                  v-model="state.submitter_phone"
                                  min="0"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />

                                <label class="mb-3">{{ lan.partners[2].quote_page[0].E_mail}}<span>*</span></label>
                                <br />
                                <input
                                  type="mail"
                                  name="email"
                                  :placeholder="
                                    lan.partners[2].quote_page[0].E_mail
                                  "
                                  :class="v$.submitter_email.$error ? 'input-error' : ''"
                                  v-model="state.submitter_email"
                                  :disabled="
                                    status == 'New' || status == 'Saved'
                                      ? false
                                      : true
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div action="" class="mt-4">
                              <div class="input">
                                <label class="mb-3">{{
                                  lan.partners[2].quote_page[0].Grand_Total
                                }}</label
                                ><br />
                                <!-- <strong class="mb-3">{{ grand_total }}</strong> -->
                                <input
                                  class="mb-3"
                                  type="number"
                                  style="width: 30%"
                                  name="name"
                                  placeholder="Grand Total"
                                  v-model="grand_total"
                                  disabled
                                  min="0"
                                />

                                <br />

                                <label class="mb-3">{{
                                  lan.partners[2].quote_page[0].Discount
                                }}</label
                                ><br />
                                <input
                                  type="number"
                                  name="number"
                                  style="width: 30%"
                                  placeholder="Total Discount"
                                  v-model="grand_discount"
                                  disabled
                                  min="0"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 align-self-center">
                            <div class="msg-highlight d-inline-block p-md-5 p-4 mb-3">
                              <h5 v-if="status == 'New' || status == 'Saved'" >{{ lan.partners[2].quote_page[0].You_cant_edit_after_submit }}</h5>
                              <h5 v-else>{{ lan.partners[2].quote_page[0].This_Quote_is_already_submitted }}</h5>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex justify-content-end mt-5 edit-quote-btnDiv">
                          <button
                          v-if="!isOpportunity_Won && (status == 'New' || status == 'Saved')"
                            class="filter-btn ml-sm-3 ml-0"
                            @click="saveQuote('edit')"
                            style="min-width: 177px;"
                            :disabled="
                              status == 'New' || status == 'Saved'
                                ? false
                                : true
                            "
                          >
                            <div
                              class="lds-ring"
                              v-show="loadingSaveNewQuoteLoader2"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSaveNewQuoteText2">
                              {{
                                lan.partners[2].quote_page[0].Submit_quote
                              }}</span
                            >
                          </button>
                          <button
                          v-if="!isOpportunity_Won && (status == 'New' || status == 'Saved')"
                            class="search-btn ml-sm-3 ml-0"
                            @click="saveQuote('save')"
                            style="min-width: 177px;"
                            :disabled="
                              status == 'New' || status == 'Saved'
                                ? false
                                : true
                            "
                          >
                            <div
                              class="lds-ring"
                              v-show="loadingSaveNewQuoteLoader"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSaveNewQuoteText">
                              {{
                                lan.partners[2].quote_page[0].Save_quote
                              }}</span
                            >
                          </button>
                          <button @click="goBack()" class="filter-btn ml-sm-3 ml-3">
                            {{ status == 'New' ? `${lan.partners[2].quote_page[0].Cancel}` : `${lan.partners[2].quote_page[0].Close}` }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    <!-- VIEW FULL PDF Modal -->
    <div class="modal fade pr-0" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 1500px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{lan.partners[2].quote_page[0].Full_Pdf_View}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <embed
        :src="fullPdfImage"
        type="video/webm"
        width="100%"
        style="max-height: 70rem; min-height: 37rem"
      />
      </div>
      <div class="modal-footer py-2">
        <button type="button" class="filter-btn mb-0" data-dismiss="modal">{{lan.partners[2].quote_page[0].Close}}</button>
      </div>
    </div>
  </div>
    </div>

   <!-- Preview image Modal -->
   <PreviewImageModal :expandedImage = "expandedImage"/>
   <preliminaryBooking :apiData="preliminaryBookingData" @callQuote="getQuotes"/>


      <!--**********************************
            Content body end
        ***********************************-->
    </div>
    <!--**********************************
      Content body end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex'
import PreviewImageModal from "../../../components/previewImageModal";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import preliminaryBooking from "../../../components/preliminaryBookingComponent";

export default {
  name: "Partner Quotes",
  components: { PreviewImageModal, preliminaryBooking },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      submitter_name: "",
      submitter_phone: "",
      submitter_email: "",
    });

    const rules = computed(() => {
      return {
        submitter_name: { required },
        submitter_phone: { required },
        submitter_email: { required, email },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      editor: ClassicEditor,
      quote_detail: "",
      account_detail: "",
      contact_detail: "",
      opportunity_detail: "",
      accomodation_details: "",
      meals_details: "",
      common_questions_details: "",
      conference_room_details: "",
      other_product_details: "",
      additional_questions: "",
      predefined_comment_from_account: [],
      accomodation_details_with_filtter: "",

      show_next_div: false,
      Id: localStorage.getItem("editID"),

      discount: "",
      opportunity_id: "",
      account_id: "",
      contact_id: "",
      response: "",
      customer_account_id: "",
      customer_account_detail_name: "",
      price: 0,
      total_discount: 0,
      price_should_be: 11011,
      commission: "",
      meals_commission: "",
      description: "",
      type_of_conference: "",
      courses_in_dinner: "",
      row_predefined_text1: "",
      row_predefined_text2: "",
      row_predefined_text3: "",
      row_predefined_text4: "",
      row_predefined_text_area: "",
      // text: [{ one: "" }, { two: '' }, { three: '' }, { four: '' }],
      text: [],
      select_predefined_text: "",
      free_of_charge_cancellation: {
        date: "8",
        week: "Week",
      },
      questions: {
        one: "",
        two: "",
        three: "",
        four: "",
      },
      number_of_participants: "",
      info_regarding_file: "",
      // submitter_name: "",
      // submitter_phone: "",
      // submitter_email: "",
      status: "",
      lost_reason: "",
      comments: "",
      comments2: "",
      additional_comment: "",
      all_attachments: [],
      isOpportunity_Won:false,

      preview_all_attachments: [],

      loadingS: true,
      loading: false,
      loadingSaveNewQuoteText: true,
      loadingSaveNewQuoteLoader: false,
      loadingSaveNewQuoteText1: true,
      loadingSaveNewQuoteLoader1: false,
      loadingSaveNewQuoteText2: true,
      loadingSaveNewQuoteLoader2: false,

      Add_more_buttons: 1,

      filehai: "",
      hasUnsavedChanges: false,
      accomodation_details_for_dinner_course: false,
      jsonValidations: false,

      expandedImage: null,
      fullPdfImage: null,
      preliminaryBookingData: null,
    };
  },

  created() {
    // this.$store.dispatch("tryAutoLogin");
    // if (!this.$store.getters.isPartner) {
    //   this.AddToast(
    //     "You can't access Partner Portal",
    //     " Warning ",
    //     "warning_1"
    //   );
    //   setTimeout(() => {
    //     alert("First logout then Login partner portal.");
    //     this.$router.back();
    //   }, 3000);
    // }
    this.$store.dispatch("GetProducts")
    
    setTimeout(() => {
      this.$watch(
        () => ({
          submitter_name: this.state.submitter_name,
          submitter_phone: this.state.submitter_phone,
          submitter_email: this.state.submitter_email,
          accomodation_details: this.accomodation_details,
          meals_details: this.meals_details,
          common_questions_details: this.common_questions_details,
          conference_room_details: this.conference_room_details,
          other_product_details: this.other_product_details,
          additional_questions: this.additional_questions,
          predefined_comment_from_account: this.predefined_comment_from_account,
        }),
        (newValues, oldValues) => {
          this.hasUnsavedChanges = true;
          // do whatever you want with the new and old values
        },
        { deep: true }
      );
    }, 3000);

    setTimeout(() => {
      this.$watch(
        () => ({
          response: this.response
        }),
        (newValues, oldValues) => {
          // do whatever you want with the new and old values
      let filterdData = this.accomodation_details.map(item => ({ ...item }));
      if(this.response == 'Yes, but only if some participants share double room'){
        const matchedIndex = this.accomodation_details.findIndex(item => 'isDoubleRoom' in item);
        if(matchedIndex === -1){
          let newProduct = this.AllProductDetails.find(item => item.product_id === 9 );
          const product = {
            row_id: Date.now().toString(),
            product_id: newProduct.product_id,
            product: {...newProduct},
            date: this.opportunity_detail.date.slice(0, 10),
            room: '',
            room_we_offer: "",
            price_for_this_quote: "",
            ordinary_price: "",
            no_of_people_came: "",
            isDoubleRoom: true,
          }
          // Find the next available numeric key
          let nextKey = Object.keys(filterdData).length.toString();
        
          // Assign the new product to the next available numeric key
          filterdData[nextKey] = product
          this.accomodation_details_with_filtter = filterdData
        }
      }else{
        const filteredWithoutDoubleRoom = filterdData.filter(item => !('isDoubleRoom' in item));
        // console.log('filteredWithoutDoubleRoom --*--',filteredWithoutDoubleRoom )

        this.accomodation_details = filteredWithoutDoubleRoom
        this.accomodation_details_with_filtter = filteredWithoutDoubleRoom 
      }
        },
        { deep: true }
      );
    }, 3000);
  },
  

  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const confirmMessage =
        "Are you sure you want to leave?, Please save all the changes before leaving.";
      if (confirm(confirmMessage)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  computed: {
    ...mapGetters({
      preBookDetails: 'getPreliminaryBooking',
      AllProductDetails: 'Ge_AllProductDetails'
    }),
    lan() {
      return this.$store.getters.whichLanguage;
    },
    accomodation_total_ammount: function () {
      var total = 0;
      for (let i = 0; i < this.accomodation_details_with_filtter.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          (this.response != "Yes"
            ? this.accomodation_details_with_filtter[i].room_we_offer
            : this.accomodation_details_with_filtter[i].room) *
            this.accomodation_details_with_filtter[i].price_for_this_quote;
      }
      // this.accomodation_details_with_filtter.forEach(element => {
      // });
      this.price = total;
      return total;
    },
    accomodation_total_discount: function () {
      var total = 0;
      for (let i = 0; i < this.accomodation_details_with_filtter.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          (this.response != "Yes"
            ? this.accomodation_details_with_filtter[i].room_we_offer
            : this.accomodation_details_with_filtter[i].room) *
            (this.accomodation_details_with_filtter[i].ordinary_price -
              this.accomodation_details_with_filtter[i].price_for_this_quote);
      }
      // this.accomodation_details_with_filtter.forEach(element => {
      // });
      this.total_discount = total;
      return total;
    },
    other_product_total_ammount: function () {
      var total = 0;
      for (let i = 0; i < this.other_product_details.length; i++) {
        //  result += permission[i] + '<br>'
        total = total + (this.other_product_details[i].room * this.other_product_details[i].price_for_this_quote);
      }
      // this.accomodation_details.forEach(element => {
      // });
      this.price = total;
      return total;
    },
    other_product_total_discount: function () {
      var total = 0;
      for (let i = 0; i < this.other_product_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total + this.other_product_details[i].room * 
          (this.other_product_details[i].ordinary_price -
            this.other_product_details[i].price_for_this_quote);
      }
      // this.accomodation_details.forEach(element => {
      // });
      this.total_discount = total;
      return total;
    },
    meals_total_ammount: function () {
      var total = 0;
      for (let i = 0; i < this.meals_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          this.meals_details[i].room *
            this.meals_details[i].price_for_this_quote;
      }
      // this.meals_details.forEach(element => {
      // });
      this.price = total;
      return total;
    },
    meals_total_discount: function () {
      var total = 0;
      for (let i = 0; i < this.meals_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          this.meals_details[i].room *
            (this.meals_details[i].ordinary_price -
              this.meals_details[i].price_for_this_quote);
      }
      // this.meals_details.forEach(element => {
      // });
      this.total_discount = total;
      return total;
    },
    grand_total: function () {
      let total = 0;
      for (let i = 0; i < this.conference_room_details.length; i++) {
        //  result += permission[i] + '<br>'
        total = total + this.conference_room_details[i].fee_confrence_room * 1;
      }
      for (let i = 0; i < this.accomodation_details_with_filtter.length; i++) {
        //  result += permission[i] + '<br>'
        // console.log("total 3-", total);
        total =
          total +
          (this.response != "Yes"
            ? this.accomodation_details_with_filtter[i].room_we_offer
            : this.accomodation_details_with_filtter[i].room) *
            this.accomodation_details_with_filtter[i].price_for_this_quote;
      }
      for (let i = 0; i < this.meals_details.length; i++) {
        //  result += permission[i] + '<br>'
        // console.log("total 3-", total);
        total =
          total +
          this.meals_details[i].room *
            this.meals_details[i].price_for_this_quote;
      }
      for (let i = 0; i < this.other_product_details.length; i++) {
        //  result += permission[i] + '<br>'
        // console.log("total 3-", total);
        total = total + (this.other_product_details[i].room * this.other_product_details[i].price_for_this_quote);
      }
      // total == 0 ? 0 : total;

      return total;
    },
    grand_discount: function () {
      var total = 0;
      for (let i = 0; i < this.accomodation_details_with_filtter.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          (this.response != "Yes"
            ? this.accomodation_details_with_filtter[i].room_we_offer
            : this.accomodation_details_with_filtter[i].room) *
            (this.accomodation_details_with_filtter[i].ordinary_price -
              this.accomodation_details_with_filtter[i].price_for_this_quote);
      }
      for (let i = 0; i < this.meals_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          this.meals_details[i].room *
            (this.meals_details[i].ordinary_price -
              this.meals_details[i].price_for_this_quote);
      }
      for (let i = 0; i < this.conference_room_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total +
          (this.conference_room_details[i].ordinary_price -
            this.conference_room_details[i].fee_confrence_room);
      }
      for (let i = 0; i < this.other_product_details.length; i++) {
        //  result += permission[i] + '<br>'
        total =
          total + this.other_product_details[i].room *
          (this.other_product_details[i].ordinary_price -
            this.other_product_details[i].price_for_this_quote);
      }
      return total;
    },
    renderDynamicHTML() {
        if (this.status === 'Denied') {
            return `
                <div class="yellowish-box p-3" style="background-color: rgb(251, 251, 138); height: 100%;">
                    <h4 class="d-block">${this.lan.partners[2].quote_page[0].Quote_Denied}</h4>
                    <p class="mb-0">${this.lost_reason}</p>
                </div>
            `;
        } else if (this.isOpportunity_Won && this.customer_account_detail_name && (this.quote_detail.booked === '1' || this.quote_detail.booked === 1)) {
            return `
                <div class="yellowish-box p-3" style="background-color: rgb(155, 238, 154); height: 100%;">
                    <h4 class="d-block">${this.lan.partners[2].quote_page[0].Quote_Booked}</h4>
                    <h6 class="mb-0">${this.customer_account_detail_name[0].name}</h6>
                </div>
            `;
        } else if (this.isOpportunity_Won && this.customer_account_detail_name && (this.quote_detail.booked === '0' || this.quote_detail.booked === 0)) {
            return `
                <div class="yellowish-box p-3" style="background-color: rgb(238, 165, 154); height: 100%;">
                    <h4 class="d-block">${this.lan.partners[2].quote_page[0].Quote_Booked_diffrent_venue}</h4>
                    <h6 class="mb-0">${this.customer_account_detail_name[0].name}</h6>
                </div>
            `;
        } else if (this.status === 'Preliminary Booking') {
            return `
                <div class="yellowish-box p-3" style="background-color: rgb(238, 165, 154); height: 100%;">
                    <h4 class="d-block">${this.lan.partners[2].quote_page[0].Admin_send_you_mail_to_preliminary_booking}</h4>
                    <h6 class="mb-0"><button class="search-btn pt-2" @click="openPreliminaryBooking()">Respond to prel.booking</button></h6>
                </div>
            `;
        } else if (this.status === 'Preliminary Booked') {
            return `
                <div class="yellowish-box p-3" style="background-color: rgb(238, 165, 154); height: 100%;">
                    <h4 class="d-block">${this.status}</h4>
                    <h6 class="mb-0"><button class="search-btn pt-2" @click="openPreliminaryBooking()">Respond to prel.booking</button></h6>
                </div>
            `;
        }
        // You can add more conditions as needed
        return ''; // Return an empty string if no condition matches
    }
  },
  watch: {
    accomodation_details_with_filtter(newValue, oldValue){
      let check = false;
      for (let i = 0; i < this.accomodation_details_with_filtter.length; i++) {
        if (this.accomodation_details_with_filtter[i].product.no_of_courses === 1 || this.accomodation_details_with_filtter[i].product.no_of_courses == '1') {
        check = true;
        break; // Exit the loop as soon as a match is found
      }
      }
      this.accomodation_details_for_dinner_course = check
    },
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    axios
      .get(axios.defaults.baseURL + "quote/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((quote_res) => {
        // console.log("quote_res", quote_res);
        const QuoteRes = quote_res.data.data[0];
        // const user = JSON.parse(localStorage.getItem("user"))
        // console.log('user',user);
        // if(user.roles != 1){
        //   if(user.company != QuoteRes.account_id){
        //     this.AddToast("This data does not exist", "Error", "error_1");
        //     // this.$router.push('*')
        //     return
        //   }
        // }
        this.quote_detail = QuoteRes;
        (this.opportunity_id = QuoteRes.opportunity_id),
          (this.account_id = QuoteRes.account_id),
          // (this.contact_id = QuoteRes.contact_id),
          (this.response = QuoteRes.response);
        this.customer_account_detail_name =
          QuoteRes.customer_account_detail;
        this.customer_account_id = QuoteRes.customer_account_id;
        this.price = QuoteRes.price;
        this.type_of_conference = QuoteRes.type_of_conference;
        this.commission = QuoteRes.commission;
        this.meals_commission = QuoteRes.meals_commission;
        this.price_should_be = QuoteRes.price_should_be;
        this.description = QuoteRes.description;
        this.status = QuoteRes.status;
        this.lost_reason = QuoteRes.lost_reason;
        if (QuoteRes.free_of_charge_cancellation != "") {
          this.free_of_charge_cancellation = JSON.parse(
            QuoteRes.free_of_charge_cancellation
          );
        }
        if (
          this.response == "Yes" ||
          this.response ==
            "Yes, but only if some participants share double room"
        ) {
          this.show_next_div = true;
        }
        this.comments = QuoteRes.comments;
        this.courses_in_dinner = QuoteRes.courses_in_dinner;
        this.comments2 = QuoteRes.comments2;
        this.additional_comment = QuoteRes.additional_comment;
        this.state.submitter_name = QuoteRes.submitter_name;
        this.state.submitter_phone = QuoteRes.submitter_phone;
        this.state.submitter_email = QuoteRes.submitter_email;
        if (QuoteRes.text != "") {
          this.text = JSON.parse(QuoteRes.text);
          this.row_predefined_text1 = this.text[0].predefined_text1;
          this.row_predefined_text2 = this.text[1].predefined_text2;
          this.row_predefined_text3 = this.text[2].predefined_text3;
          this.row_predefined_text4 = this.text[3].predefined_text4;
          this.row_predefined_text_area = this.text[4].predefined_text_area;
        }
        this.questions =
          QuoteRes.questions == ""
            ? { one: "", two: "", three: "", four: "" }
            : JSON.parse(QuoteRes.questions);
        if (QuoteRes.additional_questions != "") {
          this.additional_questions = JSON.parse(
            QuoteRes.additional_questions
          );
        }

        if (QuoteRes.attachments != "" && QuoteRes.attachments != [] && QuoteRes.attachments != [null] && QuoteRes.attachments != null && QuoteRes.attachments != '[null]' && QuoteRes.attachments != "[\"null\"]") {
          // this.preview_all_attachments = JSON.parse(QuoteRes.attachments)
          this.preview_all_attachments = QuoteRes.attachments.split(",").filter(Boolean);
          
          const fileInfo  = this.info_regarding_file = QuoteRes.info_regarding_file != '' ? JSON.parse(QuoteRes.info_regarding_file) : '';
          if(this.preview_all_attachments != null && this.preview_all_attachments != 'null' && this.preview_all_attachments != '[null]'){
            this.preview_all_attachments.map((item, index) => {
              if(item != null && item != "" && item != "null"){
                if (item.includes(".pdf")) {
                  this.all_attachments.push({
                    file: item,
                    preview: '',
                    name: fileInfo.length ? fileInfo[index].name : '',
                    type: fileInfo.length ? fileInfo[index].type : '',
                    message: fileInfo.length ? fileInfo[index].message : '',
                    index: fileInfo.length ? fileInfo[index].index : ''
                  });
                } else {
                  this.all_attachments.push({
                    file: item,
                    preview: '',
                    name: fileInfo.length ? fileInfo[index].name : '',
                    type: fileInfo.length ? fileInfo[index].type : '',
                    message: fileInfo.length ? fileInfo[index].message : '',
                    index: fileInfo.length ? fileInfo[index].index : ''
                  });
                }
              }
            });
          }
        }
        
        // **************Get Account details************
        axios
          .get(
            axios.defaults.baseURL + "account/" + QuoteRes.account_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((account_res) => {
            this.account_detail = account_res.data.data[0];
            if(this.account_detail != undefined){
              this.contact_id = this.account_detail.contact_detail != "" ? this.account_detail.contact_detail.id : '';
              // var obj = {}
  
              // for (let index = 0; index < 4; index++) {
              if (this.account_detail.predefined_comment1 != "") {
                // var name = `'comment' ${index+1} `;
                this.predefined_comment_from_account.push(
                  this.account_detail.predefined_comment1
                );
              }
              if (this.account_detail.predefined_comment2 != "") {
                // var name = `'comment' ${index+1} `;
                this.predefined_comment_from_account.push(
                  this.account_detail.predefined_comment2
                );
              }
              if (this.account_detail.predefined_comment3 != "") {
                // var name = `'comment' ${index+1} `;
                this.predefined_comment_from_account.push(
                  this.account_detail.predefined_comment3
                );
              }
              if (this.account_detail.predefined_comment4 != "") {
                // var name = `'comment' ${index+1} `;
                this.predefined_comment_from_account.push(
                  this.account_detail.predefined_comment4
                );
              }
            }
            // }
          })
          .catch((err) => {
            console.log("err", err);
          });
        // *****************************

        // **************Get Price accomodation details************
        // axios
        //   .get(
        //     axios.defaults.baseURL +
        //       "get-price-of-accomodation/" +
        //       res.data.data[0].quote_id,
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.getItem("token"),
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     console.log("price accomodation details- ", res);
        //     if (res.data.data.length != 0) {
        //       this.accomodation_details = res.data.data;
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //   });
        // *****************************

        // **************Get Opportunity details************
        axios
          .get(
            axios.defaults.baseURL +
              "opportunity/" +
              QuoteRes.opportunity_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((opportunity_res) => {
            if(opportunity_res.data.data[0].status == 'Won'){
              this.isOpportunity_Won = true;
              
            }
            this.opportunity_detail = JSON.parse(
              JSON.stringify(opportunity_res.data.data[0])
            );
            this.common_questions_details = JSON.parse(
              this.opportunity_detail.common_questions
            );

            // **************accomodation***********
            // console.log('******accomodation*****',JSON.parse(this.opportunity_detail.accomodation));
            // console.log('QuoteRes.price_for_accomodation-1-',QuoteRes.price_for_accomodation);
            // console.log('QuoteRes.price_for_accomodation--',JSON.parse(QuoteRes.price_for_accomodation));
            // if (this.opportunity_detail.accomodation != "") {
            //   let AccommData_Oppo = JSON.parse(this.opportunity_detail.accomodation);
            //   let AccommData_Quote = JSON.parse(QuoteRes.price_for_accomodation);
            //   var filtered_Accomodation_Array = []
            //   if (AccommData_Quote != "") {
            //     if(AccommData_Quote.length == AccommData_Oppo.length){
            //       var check_length = false
            //       for (let i = 0; i < AccommData_Oppo.length; i++) {
            //         if (AccommData_Quote[i].row_id !== AccommData_Oppo[i].row_id) {
            //           console.log('break--',AccommData_Quote[i].row_id, '--', AccommData_Oppo[i].row_id);
            //           check_length = true;
            //           break; // If ids don't match at any index, arrays don't match
            //         }
            //       }
            //       if(check_length){
            //         AccommData_Oppo.forEach((element) => {
            //           var isIDmatched = false
            //           for (let i = 0; i < AccommData_Quote.length; i++) {
            //             if (AccommData_Quote[i].row_id == element.row_id) {
            //               isIDmatched = true
            //               console.log('break-- 1--',AccommData_Quote[i].row_id, '--', element.row_id);
            //               filtered_Accomodation_Array.push({
            //                 row_id: element.row_id,
            //                 date: element.date,
            //                 product: element.product,
            //                 no_of_people_came: AccommData_Quote[i].no_of_people_came,
            //                 ordinary_price: AccommData_Quote[i].ordinary_price,
            //                 price_for_this_quote: AccommData_Quote[i].price_for_this_quote,
            //                 room: element.room,
            //                 room_we_offer: AccommData_Quote[i].room_we_offer,
            //               })
            //               AccommData_Quote.splice(i, 1)
            //               break; // If ids don't match at any index, arrays don't match
            //             }
            //           }
            //           if(!isIDmatched){
            //             filtered_Accomodation_Array.push(element)
            //           }
            //         })
            //       }

            //     }else{

            //       AccommData_Oppo.forEach((element) => {
            //         var isIDmatched = false
            //         for (let i = 0; i < AccommData_Quote.length; i++) {
            //           if (AccommData_Quote[i].row_id == element.row_id) {
            //             isIDmatched = true
            //             console.log('break-- 2--',AccommData_Quote[i].row_id, '--', element.row_id);
            //             filtered_Accomodation_Array.push({
            //               row_id: element.row_id,
            //               date: element.date,
            //               product: element.product,
            //               no_of_people_came: AccommData_Quote[i].no_of_people_came,
            //               ordinary_price: AccommData_Quote[i].ordinary_price,
            //               price_for_this_quote: AccommData_Quote[i].price_for_this_quote,
            //               room: element.room,
            //               room_we_offer: AccommData_Quote[i].room_we_offer,
            //             })
            //             AccommData_Quote.splice(i, 1)
            //               break; // If ids don't match at any index, arrays don't match
            //           }
            //         }
            //         if(!isIDmatched){
            //           filtered_Accomodation_Array.push(element)
            //         }
            //       })

            //     }
            //     console.log('filtered_Accomodation_Array',filtered_Accomodation_Array);
            //     this.accomodation_details = filtered_Accomodation_Array
            //   }
            //   else{
            //     this.accomodation_details = JSON.parse(
            //     this.opportunity_detail.accomodation
            //   );
            //   }
            // }else{
            //   this.accomodation_details = JSON.parse(
            //     this.opportunity_detail.accomodation
            //   );
            // }



            if (this.opportunity_detail.accomodation) {
              const AccommData_Oppo = JSON.parse(this.opportunity_detail.accomodation);
              
              var AccommData_Quote = "";
              if (QuoteRes.price_for_accomodation != "") {
                AccommData_Quote = JSON.parse(QuoteRes.price_for_accomodation)
                this.accomodation_details = JSON.parse(
                  QuoteRes.price_for_accomodation
                );
              }
              if (AccommData_Quote) {
                const filtered_Array = [];
                AccommData_Oppo.forEach((element) => {
                  const matchedIndex = AccommData_Quote.findIndex(item => item.row_id === element.row_id);
                  if (matchedIndex !== -1) {
                    filtered_Array.push({
                        ...element,
                        no_of_people_came: AccommData_Quote[matchedIndex].no_of_people_came,
                        ordinary_price: AccommData_Quote[matchedIndex].ordinary_price,
                        price_for_this_quote: AccommData_Quote[matchedIndex].price_for_this_quote,
                        room_we_offer: AccommData_Quote[matchedIndex].room_we_offer,
                    });
                    AccommData_Quote.splice(matchedIndex, 1);
                  } 
                  else {
                    if(!('isDoubleRoom' in element)){
                      filtered_Array.push(element);
                    }
                  }
                });
                if(AccommData_Quote.length){
                  filtered_Array.push(...AccommData_Quote);
                }
                // if(AccommData_Quote){
                //   AccommData_Quote.forEach((element) => {
                //     filtered_Array.push(element);
                //   })
                // }
                this.accomodation_details = filtered_Array;
                this.accomodation_details_with_filtter = filtered_Array;
              } 
              else {
                this.accomodation_details = AccommData_Oppo;
                this.accomodation_details_with_filtter = AccommData_Oppo;
              }
            } 
            else {
              this.accomodation_details = [];
              this.accomodation_details_with_filtter = [];
            }
            // **************************

            // **************meals***********

            if (this.opportunity_detail.meals) {
              const MealsData_Oppo = JSON.parse(this.opportunity_detail.meals);
              var MealsData_Quote = "";
              if (QuoteRes.price_for_meals != "") {
                MealsData_Quote = JSON.parse(QuoteRes.price_for_meals)
                this.meals_details = JSON.parse(
                  QuoteRes.price_for_meals
                );
              }
              if (MealsData_Quote) {
                const filtered_Array = [];
                MealsData_Oppo.forEach((element) => {
                  const matchedIndex = MealsData_Quote.findIndex(item => item.row_id === element.row_id);
                  if (matchedIndex !== -1) {
                    filtered_Array.push({
                        ...element,
                        no_of_people_came: MealsData_Quote[matchedIndex].no_of_people_came,
                        ordinary_price: MealsData_Quote[matchedIndex].ordinary_price,
                        price_for_this_quote: MealsData_Quote[matchedIndex].price_for_this_quote,
                        room_we_offer: MealsData_Quote[matchedIndex].room_we_offer,
                    });
                    MealsData_Quote.splice(matchedIndex, 1);
                  } 
                  else {
                    filtered_Array.push(element);
                  }
                });
                // console.log('filtered_Array', filtered_Array);
                this.meals_details = filtered_Array;
              } 
              else {
                this.meals_details = MealsData_Oppo;
              }
            } 
            else {
              this.meals_details = [];
            }


            // if (this.opportunity_detail.meals != "") {
            //   var meals_V = JSON.parse(this.opportunity_detail.meals);
            //   if (
            //     QuoteRes.price_for_meals == "" ||
            //     this.meals_details.length != meals_V.length
            //   ) {
            //     if (QuoteRes.price_for_meals == "") {
            //       this.meals_details = JSON.parse(
            //         this.opportunity_detail.meals
            //       );
            //     } else {
            //       meals_V.forEach((element, index) => {
            //         if (this.meals_details.length <= index) {
            //           this.meals_details.push(element);
            //         }
            //       });
            //     }
            //   }
            // }
            // **************************

            // **************Other products***********

            if (this.opportunity_detail.other_products) {
              const OtherProduct_Data_Oppo = JSON.parse(this.opportunity_detail.other_products);
              var OtherProduct_Data_Quote = "";
              if (QuoteRes.price_for_other_products != "") {
                OtherProduct_Data_Quote = JSON.parse(QuoteRes.price_for_other_products)
                this.other_product_details = JSON.parse(
                  QuoteRes.price_for_other_products
                );
              }
              if (OtherProduct_Data_Quote) {
                const filtered_Array = [];
                OtherProduct_Data_Oppo.forEach((element) => {
                  const matchedIndex = OtherProduct_Data_Quote.findIndex(item => item.row_id === element.row_id);
                  if (matchedIndex !== -1) {
                    filtered_Array.push({
                        ...element,
                        comment: OtherProduct_Data_Quote[matchedIndex].comment,
                        no_of_people_came: OtherProduct_Data_Quote[matchedIndex].no_of_people_came,
                        ordinary_price: OtherProduct_Data_Quote[matchedIndex].ordinary_price,
                        price_for_this_quote: OtherProduct_Data_Quote[matchedIndex].price_for_this_quote,
                        room_we_offer: OtherProduct_Data_Quote[matchedIndex].room_we_offer,
                    });
                    OtherProduct_Data_Quote.splice(matchedIndex, 1);
                  } 
                  else {
                    filtered_Array.push(element);
                  }
                });
                // console.log('filtered_Array', filtered_Array);
                this.other_product_details = filtered_Array;
              } 
              else {
                this.other_product_details = OtherProduct_Data_Oppo;
              }
            } 
            else {
              this.other_product_details = [];
            }



            // if (this.opportunity_detail.other_products != "") {
            //   var other_product_V = JSON.parse(
            //     this.opportunity_detail.other_products
            //   );
            //   if (
            //     QuoteRes.price_for_other_products == "" ||
            //     this.other_product_details.length != other_product_V.length
            //   ) {
            //     if (QuoteRes.price_for_other_products == "") {
            //       this.other_product_details = JSON.parse(
            //         this.opportunity_detail.other_products
            //       );
            //     } else {
            //       other_product_V.forEach((element, index) => {
            //         if (this.other_product_details.length <= index) {
            //           this.other_product_details.push(element);
            //         }
            //       });
            //     }
            //   }
            // }
            // **************************

            // **************additional_questions***********
            // if (this.opportunity_detail.additional_questions) {
            //   var additional_V = JSON.parse(
            //     this.opportunity_detail.additional_questions
            //   );
            //   if (
            //     QuoteRes.additional_questions == "" ||
            //     this.additional_questions.length != additional_V.length
            //   ) {
            //     if (QuoteRes.additional_questions == "") {
            //       this.additional_questions = JSON.parse(
            //         this.opportunity_detail.additional_questions
            //       );
            //     } else {
            //       additional_V.forEach((element, index) => {
            //         if (this.additional_questions.length <= index) {
            //           this.additional_questions.push(element);
            //         }
            //       });
            //     }
            //   }
            // }


            if (this.opportunity_detail.additional_questions) {
              const AdditionalQuestion_oppo = JSON.parse(this.opportunity_detail.additional_questions);
              var AdditionalQuestion_quote = "";
              if (QuoteRes.additional_questions != "") {
                AdditionalQuestion_quote = JSON.parse(QuoteRes.additional_questions)
                this.additional_questions = JSON.parse(
                  QuoteRes.additional_questions
                );
              }
              // console.log('AdditionalQuestion_quote,',AdditionalQuestion_quote);
              if (AdditionalQuestion_quote) {
                const filtered_Array = [];
                AdditionalQuestion_oppo.forEach((element) => {
                  const matchedIndex = AdditionalQuestion_quote.findIndex(item => item.row_id === element.row_id);
                  if (matchedIndex !== -1) {
                  if(AdditionalQuestion_quote[matchedIndex].inputType == 'dropdown'){
                    filtered_Array.push({
                      ...element,
                        dropdown: AdditionalQuestion_quote[matchedIndex].dropdown,
                        answertags: AdditionalQuestion_quote[matchedIndex].answertags,
                        answerMultiple: AdditionalQuestion_quote[matchedIndex].answerMultiple,
                        maxSelect: AdditionalQuestion_quote[matchedIndex].maxSelect,
                        answer: AdditionalQuestion_quote[matchedIndex].answer,
                        inputType: AdditionalQuestion_quote[matchedIndex].inputType,
                        isShowOrNot: AdditionalQuestion_quote[matchedIndex].isShowOrNot,
                        question: AdditionalQuestion_quote[matchedIndex].question,
                        row_id: AdditionalQuestion_quote[matchedIndex].row_id,
                        extra2: AdditionalQuestion_quote[matchedIndex].extra2,
                    });
                  }else{
                    filtered_Array.push({
                      ...element,
                        answer: AdditionalQuestion_quote[matchedIndex].answer,
                        inputType: AdditionalQuestion_quote[matchedIndex].inputType,
                        isShowOrNot: AdditionalQuestion_quote[matchedIndex].isShowOrNot,
                        question: AdditionalQuestion_quote[matchedIndex].question,
                        row_id: AdditionalQuestion_quote[matchedIndex].row_id,
                    });
                  }
                    AdditionalQuestion_quote.splice(matchedIndex, 1);
                  } 
                  else {
                    filtered_Array.push(element);
                  }
                });
                // console.log('filtered_Array', filtered_Array);
                this.additional_questions = filtered_Array;
              } 
              else {
                this.additional_questions = AdditionalQuestion_oppo;
              }
            } 
            else {
              this.additional_questions = [];
            }
            // **************************

            // **************conference_room***********

            if (this.opportunity_detail.conference_room) {
              const Conference_Data_Oppo = JSON.parse(this.opportunity_detail.conference_room);
              var Conference_Data_Quote = "";
              if (QuoteRes.price_for_conference_room != "") {
                Conference_Data_Quote = JSON.parse(QuoteRes.price_for_conference_room)
                this.conference_room_details = JSON.parse(
                  QuoteRes.price_for_conference_room
                );
              }
              if (Conference_Data_Quote) {
                const filtered_Array = [];
                Conference_Data_Oppo.forEach((element) => {
                  const matchedIndex = Conference_Data_Quote.findIndex(item => item.row_id === element.row_id);
                  if (matchedIndex !== -1) {
                    filtered_Array.push({
                        ...element,
                        request_size_we_can_offer: Conference_Data_Quote[matchedIndex].request_size_we_can_offer,
                        no_of_people_came: Conference_Data_Quote[matchedIndex].no_of_people_came,
                        seating_we_can_offer: Conference_Data_Quote[matchedIndex].seating_we_can_offer,
                        comment: Conference_Data_Quote[matchedIndex].comment,
                        ordinary_price: Conference_Data_Quote[matchedIndex].ordinary_price,
                        fee_confrence_room: Conference_Data_Quote[matchedIndex].fee_confrence_room,
                    });
                    Conference_Data_Quote.splice(matchedIndex, 1);
                  } 
                  else {
                    filtered_Array.push(element);
                  }
                });
                this.conference_room_details = filtered_Array;
              } 
              else {
                this.conference_room_details = Conference_Data_Oppo;
              }
            } 
            else {
              this.conference_room_details = [];
            }



            // if (this.opportunity_detail.conference_room != "") {
            //   var conference_room_details_V = JSON.parse(
            //     this.opportunity_detail.conference_room
            //   );
            //   if (
            //     QuoteRes.price_for_conference_room == "" ||
            //     this.conference_room_details.length !=
            //       conference_room_details_V.length
            //   ) {
            //     if (QuoteRes.price_for_conference_room == "") {
            //       this.conference_room_details = JSON.parse(
            //         this.opportunity_detail.conference_room
            //       );
            //       this.conference_room_details.forEach((element) => {
            //         element.seating_we_can_offer = element.seating;
            //       });
            //     } else {
            //       conference_room_details_V.forEach((element, index) => {
            //         if (this.conference_room_details.length <= index) {
            //           element.seating_we_can_offer = element.seating;
            //           this.conference_room_details.push(element);
            //         }
            //       });
            //     }
            //   }
            // }

            // **************Get Conatct details************
            axios
              .get(
                axios.defaults.baseURL +
                  "contact/" +
                  this.opportunity_detail.opportunity_owner,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((res) => {
                this.contact_detail = res.data.data[0];
              })
              .catch((err) => {
                console.log("err", err);
              });

            // **************************

            // ***************************

            // **************other_products***********
            var other_product_details_V = JSON.parse(
              this.opportunity_detail.other_products
            );
            if (
              QuoteRes.other_products == "" ||
              this.other_product_details.length !=
                other_product_details_V.length
            ) {
              // if (QuoteRes.other_products == "") {
              //   this.other_product_details = JSON.parse(
              //     this.opportunity_detail.other_products
              //   );
              // } else {
              //   other_product_details_V.forEach((element, index) => {
              //     if (this.other_product_details.length <= index) {
              //       this.other_product_details.push(element);
              //     }
              //   });
              // }
            }
            // ************************
          })
          .catch((err) => {
            console.log("err", err);
          });
        // *****************************
      })
      .catch((err) => {
        console.log("err quote", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
     
  },
  methods: {
    getQuotes(){
      this.$router.go();
    },
    openPreliminaryBooking(){

      this.preliminaryBookingData = null
      const data = {
        quote_detail: this.quote_detail,
        opportunity_detail: this.opportunity_detail
      }
      this.$store.dispatch("GetPreliminaryBookingByID",{data: data, comeFrom: 'edit-quote'}).then((res) => {
       
        this.preliminaryBookingData = this.preBookDetails;
        $('#priliminaryBookingModal').modal("show");
      })

      // console.log('resch');
      // const url = this.$router.resolve({
      //   name: "Preliminary Booking",
      //   params: {
      //     id: this.quote_detail.opportunity_id, 
      //     qID: this.quote_detail.quote_id,
      //     cutomerID: this.quote_detail.customer_account_id,
      //     bidderID: this.quote_detail.bidder_id,
      //     bookingID: this.quote_detail.preliminary_booking_id,
      //   },
      // }).href;

      // Open the URL in a new tab
      // window.open(url, "_blank");
      // this.$router.push({
      //   name: "Preliminary Booking",
      //   params: { 
      //     id: this.quote_detail.opportunity_id, 
      //     qID: this.quote_detail.quote_id,
      //     cutomerID: this.quote_detail.customer_account_id,
      //     bidderID: this.quote_detail.bidder_id,
      //     bookingID: this.quote_detail.preliminary_booking_id,
      //   },
      // });
    },
    formatNumber(data,text){
      // console.log('formatNumber data--',data);
      if(data['price_for_this_quote'] != 0 || data['price_for_this_quote'] == 0){
        if(text == 'Accomodation'){
          return (data['ordinary_price'] - data['price_for_this_quote']) * (this.response != 'Yes' ? data['room_we_offer'] : data['room'])
        }
        else if(text == 'Meals'){
             return (data['ordinary_price'] - data['price_for_this_quote']) * data['room']
        }else{
          return (data['ordinary_price'] - data['price_for_this_quote']) * data['room']
        }
      }
    },
    selectDropdown(q_index){
      // console.log('this.additional_questions',this.additional_questions)
      this.additional_questions.forEach((element, index) => {
        if(index == q_index){
          if(element.maxSelect == element.answertags.length){
            element.answer = ""
            return false;
          }
          this.additional_questions[q_index].dropdown.forEach((drop, index2) => {
            if(element.answer == index2){
              element.answertags.push(drop)
              element.dropdown.splice(element.answer ,1)
              element.answer = ""
            }
          })
        }
      });
    },
    selectDropdownDelete(item, q_index){
      this.additional_questions.forEach((element, index) => {
        if(index == q_index){
          this.additional_questions[q_index].answertags.forEach((drop, index2) => {
            if(item.name == drop.name){
              element.dropdown.push(drop)
              element.answertags.splice(index2 ,1)
              element.answer = ""
            }
          })
        }
      });
      // console.log('this.additional_questions 2--',this.additional_questions)
    },
  autoResize() {
  const textareas = document.querySelectorAll('.myTextarea');
  textareas.forEach((textarea) =>{
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
   });
  },
    goBack() {
      this.$router.back();
    },
    arrivalTimeFunc(time) {
      // console.log(time, "time");
      if (time != undefined) {
        return time.slice(0, 5);
      }
    },
    departureTimeFunc(time) {
      if (time != undefined) {
        return time.slice(0, 5);
      }
    },

    add_predefined_text() {
      if (
        this.row_predefined_text1 == "" ||
        this.row_predefined_text1 == this.select_predefined_text
      ) {
        this.row_predefined_text1 = this.select_predefined_text;
      } else if (
        this.row_predefined_text2 == "" ||
        this.row_predefined_text2 == this.select_predefined_text
      ) {
        this.row_predefined_text2 = this.select_predefined_text;
      } else if (
        this.row_predefined_text3 == "" ||
        this.row_predefined_text3 == this.select_predefined_text
      ) {
        this.row_predefined_text3 = this.select_predefined_text;
      } else if (
        this.row_predefined_text4 == "" ||
        this.row_predefined_text4 == this.select_predefined_text
      ) {
        this.row_predefined_text4 = this.select_predefined_text;
      }
      // this.row_predefined_text_area = [
      //   this.row_predefined_text1,
      //   this.row_predefined_text2,
      //   this.row_predefined_text3,
      //   this.row_predefined_text4,
      // ];
      if (
        !this.row_predefined_text_area.includes(this.select_predefined_text)
      ) {
        // this.row_predefined_text_area.splice(this.row_predefined_text_area.indexOf(this.select_predefined_text), 1);
        if (this.row_predefined_text_area != "") {
          this.row_predefined_text_area =
            this.row_predefined_text_area + this.select_predefined_text;
        } else {
          this.row_predefined_text_area =
            this.row_predefined_text_area + this.select_predefined_text;
        }
      } else {
      }
    },
    onFileChange2(event) {
      const maxAllowedSize = 30 * 1024 * 1024
      var size = event.target.files[0].size;
      if (size > maxAllowedSize) {
        this.AddToast(
          this.lan.The_image_should_be_less_than_30MB,
          "Information",
          "info_1"
        );
        return
      }
      var length = this.all_attachments.length
      if (length < 3) {
        if (
          ["image/png", "image/jpeg", "image/svg", "image/webp"].includes(
            event.target.files[0]["type"]
          )
        ) {
          for (var i = 0; i < event.target.files.length; i++) {
            this.all_attachments.push({
              file: event.target.files[i],
              preview: URL.createObjectURL(event.target.files[i]),
              name: event.target.files[i].name,
              type: event.target.files[i].type,
              message:'',
              index: this.all_attachments.length
            });
          }
        } else if (
          ["application/pdf"].includes(event.target.files[0]["type"])
        ) {
          // this.all_attachments.push(event.target.files[0]);
          this.all_attachments.push({
            file: event.target.files[0],
            preview: URL.createObjectURL(event.target.files[0]),
            name: event.target.files[0].name,
            type: event.target.files[0].type,
            message:'',
            index: this.all_attachments.length
          })
        } else {
          this.AddToast('Please select ( png, jpeg, svg, webp and PDF ) this type files ', "Validation", "info_1");
          return
          // var reader = new FileReader();
          // this.all_attachments.push(
          //   reader.readAsDataURL(event.target.files[0])
          // );
        }
      } else {
        this.AddToast(this.lan.You_can_add_upto_3_images, "Validation", "info_1");
      }
    },
    goToView(id){
      this.show_next_div = true;
      // Navigate to the desired location
      // window.location.assign("#afterSubmit");

     // Wait for a short delay, then scroll to the element
    setTimeout(function() {

        const afterSubmitElement = document.getElementById(id == 'youResponse' ? 'youResponse' : 'afterSubmit');
        if (afterSubmitElement) {
            afterSubmitElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100); // Adjust the delay time as needed
    },
    submit() {
    // return
      if (
        this.response == "Yes" ||
        this.response == "Yes, but only if some participants share double room"
      ) {
        this.show_next_div = true;
        window.location.assign("#afterSubmit").scrollIntoView({ behavior: 'smooth' });
      } else {
        this.loadingSaveNewQuoteLoader1 = true;
        this.loadingSaveNewQuoteText1 = false;
        var formdata = new FormData();
        formdata.append("opportunity_id", this.opportunity_id);
        formdata.append("account_id", this.account_id);
        formdata.append("contact_id", this.contact_id);
        formdata.append("response", this.response);
        formdata.append("comments", this.comments);
        axios
          .post(
            axios.defaults.baseURL +
              "response-is-no/" +
              this.quote_detail.quote_id,
            formdata,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.loadingSaveNewQuoteLoader1 = false;
            this.loadingSaveNewQuoteText1 = true;
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            // this.opportunity_id = res.data.data.opportunity_id;
            this.$router.push({ path: "/p-quotes" });
          })
          .catch((err) => {
            this.loadingSaveNewQuoteLoader1 = false;
            this.loadingSaveNewQuoteText1 = true;
            this.$store.dispatch("checkErrorAndSendToast", [err.response,"error",]);
          });
      }
    },
    saveQuote(condition) {
      // alert(this.contact_id);
      if (this.response != "Yes" && this.response != "Yes, but only if some participants share double room") {
        this.AddToast(
          this.lan.Please_select_Yes_inside_your_respose,
          "Validation",
          "info_1"
        );
        this.goToView('youResponse')
      }else{
        var check = true
        if (condition == 'edit') {
          this.v$.$validate();
          this.jsonValidations = true
          check = condition == 'edit' ? (!this.v$.$error && this.questions.four != "" && this.questions.four == 'Yes' && this.free_of_charge_cancellation.week != "" && this.free_of_charge_cancellation.date != "" && (!this.accomodation_details_for_dinner_course ? true : this.courses_in_dinner )) : false
        }
        if (check){
          if (condition == "save") {
            this.loadingSaveNewQuoteLoader = true;
            this.loadingSaveNewQuoteText = false;
          } else {
            this.loadingSaveNewQuoteLoader2 = true;
            this.loadingSaveNewQuoteText2 = false;
          }
          // ***********************Save quote****************
          // ***********************Save quote****************
  
          this.price = this.grand_total;
          var formData = new FormData();
          formData.append("opportunity_id", this.opportunity_id);
          formData.append("lost_reason", this.lost_reason);
          formData.append("date", this.opportunity_detail.date); //*********************** pending***************************** */
          formData.append("account_id", this.account_id);
          formData.append("contact_id", this.contact_id);
          formData.append("response", this.response);
          formData.append("comments", this.comments);
          formData.append("price", this.price);
          formData.append("total_discount", this.grand_discount);
          formData.append("customer_account_id", this.customer_account_id);
          if(this.commission == ""){
            formData.append("commission", this.account_detail.commission);
            formData.append("meals_commission", this.account_detail.meals_commission);
          }else{
            formData.append("commission", this.commission);
            formData.append("meals_commission", this.meals_commission);
          }
          // formData.append("description", this.description);
          formData.append("price_should_be", this.opportunity_detail.vat);
          formData.append(
            "type_of_conference",
            this.opportunity_detail.type_of_conference
          );
          formData.append("courses_in_dinner", this.courses_in_dinner);
          formData.append(
            "number_of_participants",
            this.opportunity_detail.number_of_participants
          );
          formData.append("submitter_name", this.state.submitter_name);
          
          const phoneNumberInt = parseInt(this.state.submitter_phone); // Convert the phone number to an integer
          formData.append("submitter_phone", phoneNumberInt);
          formData.append("submitter_email", this.state.submitter_email);
          formData.append("comments2", this.comments2);
          formData.append("additional_comment", this.additional_comment);
          formData.append("preliminary", this.quote_detail.preliminary);
          formData.append("is_new_partner", this.quote_detail.is_new_partner);
          if(this.quote_detail.booked == ""){
            formData.append("booked", 0);
          }else{
            formData.append("booked", this.quote_detail.booked);
          }
  
          formData.append(
            "text",
            JSON.stringify(
              (this.text = [
                { predefined_text1: this.row_predefined_text1 },
                { predefined_text2: this.row_predefined_text2 },
                { predefined_text3: this.row_predefined_text3 },
                { predefined_text4: this.row_predefined_text4 },
                { predefined_text_area: this.row_predefined_text_area },
              ])
            )
          );
  
          formData.append(
            "free_of_charge_cancellation",
            JSON.stringify(this.free_of_charge_cancellation)
          );
          formData.append("questions", JSON.stringify(this.questions));
          formData.append(
            "additional_questions",
            JSON.stringify(this.additional_questions)
          );
          formData.append(
            "price_for_conference_room",
            JSON.stringify(this.conference_room_details)
          );
          this.accomodation_details = this.accomodation_details_with_filtter
          formData.append(
            "price_for_accomodation",
            JSON.stringify(this.accomodation_details)
          );
          formData.append("price_for_meals", JSON.stringify(this.meals_details));
          formData.append(
            "price_for_other_products",
            JSON.stringify(this.other_product_details)
          );
          // var attachments_message = []
          if(this.all_attachments.length != 0){
            var attachFile = []
            for (var i = 0; i < this.all_attachments.length; i++) {
              if(this.all_attachments[i].preview){
                formData.append("new_attachments[]", this.all_attachments[i].file);
              }else{
                attachFile.push(this.all_attachments[i].file)
                // formData.append("attachments[]", this.all_attachments[i].file);
              }
              this.all_attachments[i].preview ? this.all_attachments[i].file = '' : ''
            }
            formData.append("attachments", attachFile);
          }else{ 
            formData.append("attachments", '');
            // formData.append("new_attachments[]", []);
          }
          formData.append("info_regarding_file", JSON.stringify(this.all_attachments));

          // return
          // formData.append("attachments[]", []);
          if (this.status == "Submitted") {
            formData.append("status", "Submitted");
          } else {
            condition == "save"
              ? formData.append("status", "Saved")
              : formData.append("status", "Submitted");
          }
  
          // return
          axios
            .post(
              axios.defaults.baseURL +
                condition +
                "-quote/" +
                this.quote_detail.quote_id,
              // axios.defaults.baseURL + "edit-quote/" + this.quote_detail.quote_id,
              formData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              if (condition == "save") {
                this.loadingSaveNewQuoteLoader = false;
                this.loadingSaveNewQuoteText = true;
              } else {
                this.loadingSaveNewQuoteLoader2 = false;
                this.loadingSaveNewQuoteText2 = true;
                this.status = 'Submitted'
                // this.$router.push({ path: "/p-quotes" });
              }
              this.hasUnsavedChanges = false;
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
              // this.opportunity_id = res.data.data.opportunity_id;
            })
            .catch((err) => {
              if (condition == "save") {
                this.loadingSaveNewQuoteLoader = false;
                this.loadingSaveNewQuoteText = true;
              } else {
                this.loadingSaveNewQuoteLoader2 = false;
                this.loadingSaveNewQuoteText2 = true;
              }
  
              // this.loadingSaveNewQuoteLoader2 = false;
              // this.loadingSaveNewQuoteText2 = true;
              this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
              console.log("err", err);
            });
        } else {
          this.AddToast(
            this.lan.Responder_information_all_fields_are_required_should_be_EXVAT_Have_you_responded_accordingly_be_YES + this.lan.partners[2].quote_page[0].Free_of_charge_cancellation,
            "Validation",
            "info_1"
          );
          return;
        }
      }
      // ***************************************************
    },

    fullImageGallery(image) {
      this.expandedImage = image;
    },
    closeImageGallery() {
      this.expandedImage = null;
    },
    deleteImage(index){
      this.all_attachments.splice(index, 1)
    },
    deletePdf(index){
      this.all_attachments.splice(index, 1)
    },
    getPdfImage(image){
      this.fullPdfImage = image
    },
    onFileChange(event) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.all_attachments.push(event.target.files[i]);
      }
      //   }
    },
    Add_more_button() {
      if (this.Add_more_buttons != 3) {
        this.Add_more_buttons = this.Add_more_buttons + 1;
      } else {
        this.AddToast(this.lan.You_can_add_upto_3_images, "Validation", "info_1");
        return;
      }
    },
    checkDate_ConefrenceHall(dateToCheck){
      // Parse dates
    const [startDate, endDate] = this.opportunity_detail.date.split(" to ");
    const checkDate = new Date(dateToCheck);
    const rangeStartDate = new Date(startDate);
    // const rangeEndDate = new Date(endDate);

    // Calculate difference in milliseconds
    const difference = checkDate.getTime() - rangeStartDate.getTime();
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculate difference in days
    const daysDifference = Math.floor(difference / oneDay);
    // console.log('daysDifference--',daysDifference);
    return daysDifference === 0 ? `${this.lan.Day} ${daysDifference+1}` : `${this.lan.Day} ${daysDifference+1}`;
    },
    checkSeating_type_transaltion(seatingToCheck){
      if(seatingToCheck == 'Cinema Seating'){
        return this.lan.partners[2].quote_page[0].Cinema_Seating;
      }
      else if(seatingToCheck == 'U-shaped table'){
        return this.lan.partners[2].quote_page[0].Ushaped_table;
      }
      else if(seatingToCheck == 'Boardroom'){
        return this.lan.partners[2].quote_page[0].Boardroom;
      }
      else if(seatingToCheck == 'School Seating'){
        return this.lan.partners[2].quote_page[0].School_Seating;
      }
      else if(seatingToCheck == 'Islands'){
        return this.lan.partners[2].quote_page[0].Islands;
      }
      else if(seatingToCheck == "Don't know at the moment"){
        return this.lan.partners[2].quote_page[0].Dont_know_at_the_moment;
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });

      setTimeout(() => {
        this.dismissToast(title);
      }, 7000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.initt {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.right-bordr {
  border-right: 2px solid lightgray;
}
#textarea {
  overflow: hidden;
  min-height: 60px;
}
#imgtext {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  font-size: 20px;
}
.column{
  position: relative;
}

.column .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.column:hover .overlay{
  opacity: 1;
}
.column .overlay .text{
  background-color: rgba(0, 0, 0, 0.519);
color: white;
}
.column .overlay .icon{
  width: 40px;
  height: 40px;
  border: none;
}
.column .overlay .delete{
  background-color: rgba(0, 0, 0, 0.347);
}
.column .overlay .icon i{
  font-size: 16px;
}
.column img {
  cursor: pointer;
}

/* Closable button inside the image */
.closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}
.container_img {
  position: relative;
  /* display: none; */
}
.column img:hover {
  opacity: 1;
}
.modal .modal-body .card-img-top{
  height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 2s ease-in-out;
    border-radius: 0px;
} 
.modal .modal-body .card-img-top:hover{
  object-position: bottom;
}
.del-icon-pdf .icon{
  width: 40px;
  height: 40px;
  border: 0;
}
.del-icon-pdf .icon i{
  font-size: 18px;
}
.fs{
  font-size: 15px;
}
.msg-highlight{
  background: #ffc150;
  border-end-end-radius: 20px;
}
@media only screen and (max-device-width: 576px) {
  .edit-quote-btnDiv{
    display: block !important;
  }
  .edit-quote-btnDiv .filter-btn{
    padding: 12px 25px;
    margin-bottom: 15px;
  }
  .edit-quote-btnDiv .search-btn{
    padding: 12px 25px;
  }
  .commission h4{
    font-size: 1rem;
  }
  .fs{
    font-size: 13px;
  }
}
</style>
