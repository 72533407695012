<template>
  <!--**********************************
			Main wrapper start
		***********************************-->
  <div id="main-wrapper">
    <!--**********************************
	  Content body start
  ***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">
            {{ lan.partners[6].Profile[0].Edit_account }}
          </h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <!-- <div
                    class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
                  >
                    <div class="d-flex mt-3">
                      <button
                        class="filter-btn ml-0"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Add new note
                      </button>
                      <button
                        class="filter-btn"
                        data-toggle="modal"
                        data-target="#exampleModal1"
                      >
                        Add new task
                      </button>
                    </div>
                    <div class="row">
                      <div
                        v-if="note_detail && note_detail.length"
                        class="col-lg-6"
                      >
                        <table class="pointer">
                          <h5>Notes</h5>
                          <tr
                            v-for="(data, index) in note_detail"
                            :key="index"
                            @click="editNote(data.note_id)"
                          >
                            <td>
                              <strong>{{ index + 1 }}</strong>
                            </td>
                            <td>
                              <p class="mb-0">{{ data.subject }}</p>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div
                        v-if="task_detail && task_detail.length"
                        class="col-lg-6"
                      >
                        <table class="pointer">
                          <h5>Tasks</h5>
                          <tr
                            v-for="(data, index) in task_detail"
                            :key="index"
                            @click="editTask(data.task_id)"
                          >
                            <td>
                              <strong>{{ index + 1 }}</strong>
                            </td>
                            <td>
                              <p class="mb-0">{{ data.subject }}</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div> -->

              <div class="card-body p-4">
                <div>
                  <div class="row">
                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.partners[6].Profile[0].Company_name
                        }}<span>*</span>
                        <span style="color: red">{{
                          v$.company_name.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        :placeholder="state.company_name"
                        :class="v$.company_name.$error ? 'input-error' : ''"
                        disabled
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.partners[6].Profile[0].Venue_name }}</label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="venue_name"
                        :placeholder="lan.partners[6].Profile[0].Venue_name"
                      />
                    </div>

                    <!-- <div class="col-sm-4 input">
                          <label
                            >Account Type<span>*</span
                            ><span style="color: red">{{
                              v$.type.$error ? " is required" : ""
                            }}</span></label
                          ><br />
                          <select
                            v-model="state.type"
                            :class="v$.type.$error ? 'input-error' : ''"
                            disabled
                          >
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option value="Partner">Partner</option>
                            <option value="Customer">Customer</option>
                            <option value="Admin">Admin</option>
                          </select>
                        </div> -->

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.partners[6].Profile[0].Address }}<span>*</span
                        ><span style="color: red">{{
                          v$.address.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Address"
                        v-model="state.address"
                        :placeholder="lan.partners[6].Profile[0].Address"
                        :class="v$.address.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.partners[6].Profile[0].Phone_number
                        }}<span>*</span
                        ><span style="color: red">{{
                          v$.phone.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Phone number"
                        v-model="state.phone"
                        :placeholder="lan.partners[6].Profile[0].Phone_number"
                        :class="v$.phone.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.partners[6].Profile[0].Corporate_number
                        }}<span style="color: red">{{
                          v$.corporate_number.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <div class="init p-0 d-flex border-0">
                        <input
                          type="text"
                          name="Corporate number"
                          class="mr-2"
                          v-model="state.corporate_number"
                          :placeholder="lan.partners[6].Profile[0].Corporate_number"
                          :class="
                            v$.corporate_number.$error ? 'input-error' : ''
                          "
                        />
                      </div>
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.partners[6].Profile[0].Invoice_address
                        }}<span style="color: red">{{
                          v$.invoice_address.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="Invoice address"
                        v-model="state.invoice_address"
                        :placeholder="lan.partners[6].Profile[0].Invoice_address"
                        :class="v$.invoice_address.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{
                        lan.partners[6].Profile[0].Invoice_e_mail
                      }}</label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="invoice_email"
                        :placeholder=" lan.partners[6].Profile[0].Invoice_e_mail"
                      />
                    </div>

                    <!-- <div class="col-sm-4 input">
                          <label
                            >Account owner<span>*</span
                            ><span style="color: red">{{
                              v$.account_owner.$error ? " is required" : ""
                            }}</span></label
                          ><br />
                          <select
                            v-model="state.account_owner"
                            :class="
                              v$.account_owner.$error ? 'input-error' : ''
                            "
                          >
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="data in accountOwners"
                              :value="data.id"
                              :key="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select>
                        </div> -->

                    <div v-if="state.type != 'Customer'" class="col-md-2 col-sm-4 input" v-show="false">
                      <label>{{
                        lan.admin[13].Account_page[0].Commission_type
                      }}</label
                      ><br />
                      <select name="" id="" v-model="commission_model" :placeholder=" lan.admin[13].Account_page[0].Commission_type" :disabled="state.type == 'Partner' ? true : false">
                        <option value="Single commission model">Single commission model</option>
                        <option value="Split commission model">Split commission model</option>
                      </select>
                    </div>

                    <div class="col-sm-2 input" v-show="false">
                      <label>{{
                        lan.partners[6].Profile[0].Commission_persent
                      }}</label
                      ><span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="commission_model != 'Split commission model' ?
                          lan.admin[13].Account_page[0].Commission_accommodation_conference_meals : 
                          lan.admin[13].Account_page[0].Commission_accommodation_and_conference
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span><br />
                      <input
                        type="text"
                        name="text"
                        v-model="commission"
                        :placeholder="lan.partners[6].Profile[0].Commission_persent"
                        :disabled="state.type == 'Partner' ? true : false"
                      />
                    </div>

                    <div v-if="state.type != 'Customer' && commission_model == 'Split commission model'" class="col-md-2 col-sm-4 input" v-show="false">
                      <label>{{
                        lan.admin[13].Account_page[0].Commission_persent
                      }}</label
                      >
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.admin[13].Account_page[0].Commission_on_meals
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span><br />
                      <input
                        type="text"
                        name="text"
                        v-model="meals_commission"
                        :placeholder=" lan.admin[13].Account_page[0].Commission_persent"
                        :disabled="state.type == 'Partner' ? true : false"
                      />
                    </div>

                    <div class="col-sm-2 input" v-show="false">
                      <label>{{
                        lan.partners[6].Profile[0].Payment_terms
                      }}</label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="payment_terms"
                        :placeholder="lan.partners[6].Profile[0].Payment_terms"
                        :disabled="state.type == 'Partner' ? true : false"
                      />
                    </div>

                    <div class="col-sm-4 input" v-show="false">
                      <label>{{
                        lan.partners[6].Profile[0].Primary_contact
                      }}</label
                      ><br />
                      <div class="d-flex">
                        <select v-model="primary_contact">
                          <option value="" disabled selected>
                            Please select one
                          </option>
                          <option
                            v-for="data in contactsDetailsFilter"
                            :value="data.id"
                            :key="data.id"
                          >
                            {{ data.first_name }} {{ data.last_name }} {{ data.id }}
                          </option>
                        </select>
                        <button
                          class="search-btn-new"
                          data-toggle="modal"
                          data-target="#exampleModal22"
                        >
                          {{ lan.partners[6].Profile[0].Create_new_contact }}
                        </button>
                      </div>
                    </div>

                    <div class="col-sm-8 input adjust-map">
                      <div class="row align-items-end">
                        <div class="col-lg-9 col-6">
                      <label>{{ lan.admin[13].Account_page[0].Google_map_location }}</label>
                      <br />
                      <vue-google-autocomplete
                        id="map"
                        v-on:placechanged="getAddressData"
                        :placeholder="google_map_location.formatted_address"
                      >
                      </vue-google-autocomplete>
                      </div>

                     <div class="col-lg-3 col-6">
                      <button class="filter-btn ml-0 mb-0" data-toggle="modal" data-target="#mapPreview" >
                       View Map
                      </button>
                     </div>
                    </div>
                   </div>

                   <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[13].Account_page[0].email
                        }}<span>*</span
                        ><span style="color: red">{{
                          v$.email.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="state.email"
                        :class="v$.email.$error ? 'input-error' : ''"
                        :placeholder=" lan.admin[13].Account_page[0].email"
                      />
                    </div>                   

                    <div class="col-sm-4 input">
                      <label>{{ lan.partners[6].Profile[0].Main_image }}</label
                      ><br />
                      <input
                        type="file"
                        name="file"
                        @change="onFileChange"
                        placeholder="Main image"
                        class="fileInput"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.partners[6].Profile[0].Add_image }}</label
                      ><br />
                      <input
                        type="file"
                        id="files"
                        @change="onFileChange2"
                        name="files"
                        multiple=""
                        class="fileInput"
                      />
                    </div>
                    <div class="col-sm-4 input">
                      <label>{{ lan.partners[6].Profile[0].Profile_picture }}</label
                      ><br />
                      <input
                        type="file"
                        name="file"
                        @change="onFileChangeProfile"
                        placeholder="Profile Picture"
                        class="fileInput"
                      />
                    </div>
                  </div>

                  <div>
                    <div class="d-flex flex-sm-row flex-column">
                      <div class="width-img mr-3">
                        <div v-if="image || preview_image">
                          <span for="" class="pb-4">{{ lan.partners[6].Profile[0].Main_image}}</span>
                          <div class="column">
                          <img
                            v-if="preview_image"
                            :src="preview_image"
                            class="img-flow my-0"
                          />
                          <img v-else :src="image" class="img-flow my-0" />
                          
                          <div class="overlay row justify-content-center align-items-center mx-0">
                          <div
                            class="text col-6 d-flex justify-content-center align-items-center"
                            @click="fullImageGallery(image)"
                            data-toggle="modal"
                            data-target="#exampleModal5"
                            style="height: 100%"
                          >
                            <p class="mb-0 fw-bold">{{ lan.admin[13].Account_page[0].View}}</p>
                          </div>
                          <div
                            class="delete col-6 d-flex justify-content-center align-items-center"
                            style="height: 100%"
                          >
                            <button @click="image = null" class="icon">
                              <i class="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </div>
                        </div>
                        </div>
                      </div>

                      <div
                        class="w-new ml-sm-5"
                        style="overflow: hidden;"
                        v-if="
                          (preview_other_images &&
                            preview_other_images.length) ||
                          (other_images && other_images.length)
                        "
                      >
                        <span for="">{{ lan.partners[6].Profile[0].More_Images }}</span>
                        <div class="moreIMges">
                          <div
                            v-if="
                              preview_other_images &&
                              preview_other_images.length
                            "
                            v-for="(image, index) in preview_other_images"
                            :key="index"
                            style="margin-right: 15px"
                            class="column"
                          >
                            <img :src="image" class="img-flow my-0" />

                            <div
                            class="overlay row justify-content-center align-items-center mx-0"
                          >
                            <div
                              class="text col-6 d-flex justify-content-center align-items-center"
                              @click="fullImageGallery(image)"
                              data-toggle="modal"
                              data-target="#exampleModal5"
                              style="height: 100%"
                            >
                              <p class="mb-0 fw-bold">{{ lan.admin[13].Account_page[0].View}}</p>
                            </div>
                            <div
                              class="delete col-6 d-flex justify-content-center align-items-center"
                              style="height: 100%"
                            >
                              <button
                                @click="deleteImage(image, index)"
                                class="icon"
                              >
                                <i class="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </div>
                          </div>
                          <div
                            v-else
                            v-for="(image, indexx) in other_images"
                            :key="indexx"
                            style="margin-right: 15px"
                          >
                            <img :src="image" class="img-flow" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="width-img mr-3">
                        <div v-if="profilePicture || profilePicture_preview">
                          <span for="" class="pb-4">{{
                            lan.partners[6].Profile[0].Profile_picture
                          }}</span>
                          <div class="column">
                            <img
                              v-if="profilePicture_preview"
                              :src="profilePicture_preview"
                              class="img-flow my-0"
                            />
                            <img v-else :src="profilePicture" class="img-flow my-0" />

                            <div
                              class="overlay row justify-content-center align-items-center mx-0"
                            >
                              <div
                                class="text col-6 d-flex justify-content-center align-items-center"
                                @click="fullImageGallery(profilePicture)"
                                data-toggle="modal"
                                data-target="#exampleModal5"
                                style="height: 100%"
                              >
                                <p class="mb-0 fw-bold">
                                  {{ lan.admin[13].Account_page[0].View }}
                                </p>
                              </div>
                              <div
                                class="delete col-6 d-flex justify-content-center align-items-center"
                                style="height: 100%"
                              >
                                <button @click="profilePicture = '',profilePicture_preview = ''" class="icon">
                                  <i class="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div class="input row">
                   <div class="col-12">
                    <div class="info mt-4">
                      <label>{{ lan.partners[6].Profile[0].Title }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="lan.partners[6].Profile[0].Title_info"
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                      <textarea
                        name=""
                        id=""
                        cols="20"
                        rows="5"
                        class="my-2"
                        v-model="title"
                        spellcheck="false"
                        :placeholder="lan.partners[6].Profile[0].Type_somthing_for_Title"
                      ></textarea>
                    </div>

                   <div class="col-12 description-text my-2">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Description }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="lan.partners[6].Profile[0].Description_info"
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>

                     <div class="styleCkEditor">
                      <ckeditor
                        :editor="editor"
                        v-model="description"
                        :config="editorConfig"
                        :placeholder="lan.partners[6].Profile[0].Type_somthing_for_Description"
                      >
                      </ckeditor>
                     </div>
                    </div>
                  </div>

                  <div class="input row">
                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_1 }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_1_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      spellcheck="false"
                      v-model="predefined_comment1"
                    ></textarea>
                   </div>

                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_2 }} </label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_2_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      spellcheck="false"
                      v-model="predefined_comment2"
                    ></textarea>
                  </div>
                 </div>

                  <div class="input row">
                   <div class="col-md-6">
                    <div class="info">
                      <label>{{ lan.partners[6].Profile[0].Predefinied_comment_3 }}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_3_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      spellcheck="false"
                      v-model="predefined_comment3"
                    ></textarea>
                   </div>


                   <div class="col-md-6">
                    <div class="info">
                      <label>{{lan.partners[6].Profile[0].Predefinied_comment_4}}</label>
                      <span
                        class="d-inline-block ml-3"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        :title="
                          lan.partners[6].Profile[0].Predefinied_comment_4_info
                        "
                      >
                        <i class="fa-regular fa-circle-question pointer"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="20"
                      rows="5"
                      class="my-2"
                      spellcheck="false"
                      v-model="predefined_comment4"
                    ></textarea>
                  </div>
                 </div>

                  <div class="button d-flex justify-content-end mt-4">
                    <button class="search-btn" @click="submit">
                      <div class="lds-ring" v-show="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-show="loadingS">{{
                        lan.partners[6].Profile[0].Save
                      }}</span>
                    </button>
                    <button class="filter-btn" v-on:click="route()">
                      {{ lan.partners[6].Profile[0].Cancel }}
                    </button>
                  </div>

                  <!-- <div>
                    <div style="display: flex">
                      <div class="width-img mr-3">
                        <div v-if="image && preview_image">
                          <span for="" class="pb-4">Main Image</span>
                          <img
                            v-if="preview_image"
                            :src="preview_image"
                            class="img-flow"
                          />
                          <img v-else :src="image" class="img-flow" />
                          <span for="">More Images</span>
                          <div style="display: flex">
                            <div
                              v-if="preview_other_images"
                              v-for="(image, index) in preview_other_images"
                              :key="index"
                              style="margin-right: 10px"
                            >
                              <img :src="image" height="300" width="300" />
                            </div>
                          </div>
                          </div>

                          <div class="w-new ml-3">
                            <span for=""
                              >More Images {{ preview_other_images }}
                              {{ other_images }}
                            </span>
                            <div
                              style="
                                display: flex;
                                overflow-x: scroll;
                                width: 71%;
                              "
                            >
                              <div
                                v-if="
                                  preview_other_images &&
                                  preview_other_images.length
                                "
                                v-for="(image, index) in preview_other_images"
                                :key="index"
                                style="margin-right: 15px"
                              >
                                <img :src="image" class="img-flow" />
                              </div>
                              <div
                                v-else
                                v-for="(image, indexx) in other_images"
                                :key="indexx"
                                style="margin-right: 15px"
                              >
                                <a :href="image">link</a>
                                <img :src="image" class="img-flow" />
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddConatact
          @event="getContact"
          check_partner="Partner"
          :value="user.id"
        />
      </div>
        <!-- Preview image Modal -->
        <PreviewImageModal :expandedImage="expandedImage" />
      <ViewMapLoctions :mapLocation="google_map_location" />
    </div>
    <!--**********************************
	  Content body end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'
import PreviewImageModal from "../../components/previewImageModal";
import useVuelidate from "@vuelidate/core";
import AddConatact from "../../components/addContact";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ViewMapLoctions from "../../components/mapPreview.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Partner Profile",
  components: {
    PreviewImageModal,
    VueGoogleAutocomplete,
    AddConatact,
    ViewMapLoctions
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      company_name: "",
      type: "",
      email: "",
      address: "",
      phone: "",
      corporate_number: "",
      invoice_address: "",
      account_owner: "",
    });

    const rules = computed(() => {
      return {
        company_name: { required },
        type: { required },
        email: { required, email },
        address: { required },
        phone: { required },
        corporate_number: { },
        invoice_address: { required },
        account_owner: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },

      account_details: "",
      contactsDetails: [],

      accountDetails: "",
      opportunitiesDetails: "",

      note_detail: [],
      task_detail: [],

      accountOwners: "",
      venue_name: "",
      commission: "",
      meals_commission: "",
      commission_model: "Single commission model",
      payment_terms: "",
      default_language: "",
      invoice_email: "",
      google_map_location: {},
      note: [],
      task: [],
      image: "",
      preview_image: "",
      other_images: [],
      preview_other_images: [],
      new_other_images: [],
      description: "",
      profilePicture_preview: "",
      profilePicture: "",
      title: "",
      is_outside_partner: "",
      primary_contact: "",
      predefined_comment1: "",
      predefined_comment2: "",
      predefined_comment3: "",
      predefined_comment4: "",
      user: JSON.parse(localStorage.getItem("user")),

      loading: false,
      loadingS: true,
      loadingSaveNewTaskText: true,
      loadingSaveNewTaskLoader: false,
      loadingSaveNewNoteText: true,
      loadingSaveNewNoteLoader: false,

      expandedImage: null,
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
    ...mapState({
      Account_Details: state => state.accounts.single_Account_Details
    }),
    contactsDetailsFilter() {
      var data = [];
      // var self = this;
      var type =
        this.state.type == "Partner"
          ? 2
          : this.state.type == "Customer"
          ? 3
          : 1;
      if (this.state.type != "") {
        this.contactsDetails.forEach((element) => {
          // if(element.roles == type && element.company == this.Id)
          if (element.roles == type) data.push(element);
        });
      } else {
        data = this.contactsDetails;
      }
      return data;
    },
    coverPhoto() {
      return {
        backgroundImage: this.image
          ? `url(${this.image})`
          : `url('../images/profile/cover.jpg')`,
      };
    },
  },
  watch:{
    Account_Details:{

immediate: true, // Trigger the watcher immediately
handler(newValue) {
  if (newValue) { 
    const account = newValue[0];
      this.account_details = account;
        this.profilePicture_preview = this.account_details.profile_picture
        this.profilePicture = this.account_details.profile_picture

        this.state.company_name = account.name;
        this.venue_name = account.venue_name;
        this.state.email = account.email;
        this.state.type = account.type;
        this.state.address = account.address;
        this.state.phone = account.phone;
        this.state.corporate_number = account.corporate_number;
        this.primary_contact = account.primary_contact;
        this.note = account.note;
        this.task = account.task;
        this.title = account.title;
        this.description = account.description;

        this.is_outside_partner = account.is_outside_partner;
        this.state.invoice_address = account.invoice_address;
        this.invoice_email = account.invoice_email;
        this.state.account_owner = account.account_owner;
        this.commission = account.commission;
        this.meals_commission = account.meals_commission;
        this.commission_model = account.commission_model;
        this.payment_terms = account.payment_terms;
        this.default_language = account.default_language;
        if(account.image != 'null' && account != null){
          this.image = account.image;
        }
        // this.other_images = account.other_images;
        this.predefined_comment1 = account.predefined_comment1;
        this.predefined_comment2 = account.predefined_comment2;
        this.predefined_comment3 = account.predefined_comment3;
        this.predefined_comment4 = account.predefined_comment4;
        this.note_detail =
          account.note_detail == []
            ? []
            : account.note_detail;
        this.task_detail =
          account.task_detail == []
            ? []
            : account.task_detail;
        this.google_map_location =
          account.google_map_location == ""
            ? ""
            : JSON.parse(account.google_map_location);
        if (account.other_images != "") {
          this.preview_other_images = account.other_images.split(",");
        }
        if (account.other_images != "") {
          this.other_images = account.other_images.split(",");
        }
    
  }
} 
},
  },
  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast(
  //       "You can't access Partner Portal",
  //       " Warning ",
  //       "warning_1"
  //     );
  //     setTimeout(() => {
  //       alert("First logout then Login partner portal.");
  //       this.$router.back();
  //     }, 3000);
  //   }
  // },
  mounted() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
    this.getContact();
    this.getAccount_contact_oppo();
    this.$store.dispatch("GetAccountsById",this.user.company)
    // this.$store.dispatch("GetLanguagesFromAPI")
    // this.getAccountDetails();

    // *****************Account by ID****************
  },
  methods: {
    getAccountDetails(){
      axios
      .get(axios.defaults.baseURL + "account/" + this.user.company, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.account_details = res.data.data[0];
        this.profilePicture_preview = this.account_details.profile_picture
        this.profilePicture = this.account_details.profile_picture

        this.state.company_name = res.data.data[0].name;
        this.venue_name = res.data.data[0].venue_name;
        this.state.email = res.data.data[0].email;
        this.state.type = res.data.data[0].type;
        this.state.address = res.data.data[0].address;
        this.state.phone = res.data.data[0].phone;
        this.state.corporate_number = res.data.data[0].corporate_number;
        this.primary_contact = res.data.data[0].primary_contact;
        this.note = res.data.data[0].note;
        this.task = res.data.data[0].task;
        this.title = res.data.data[0].title;
        this.description = res.data.data[0].description;

        this.is_outside_partner = res.data.data[0].is_outside_partner;
        this.state.invoice_address = res.data.data[0].invoice_address;
        this.invoice_email = res.data.data[0].invoice_email;
        this.state.account_owner = res.data.data[0].account_owner;
        this.commission = res.data.data[0].commission;
        this.meals_commission = res.data.data[0].meals_commission;
        this.commission_model = res.data.data[0].commission_model;
        this.payment_terms = res.data.data[0].payment_terms;
        this.default_language = res.data.data[0].default_language;
        if(res.data.data[0].image != 'null' && res.data.data[0] != null){
          this.image = res.data.data[0].image;
        }
        // this.other_images = res.data.data[0].other_images;
        this.predefined_comment1 = res.data.data[0].predefined_comment1;
        this.predefined_comment2 = res.data.data[0].predefined_comment2;
        this.predefined_comment3 = res.data.data[0].predefined_comment3;
        this.predefined_comment4 = res.data.data[0].predefined_comment4;
        this.note_detail =
          res.data.data[0].note_detail == []
            ? []
            : res.data.data[0].note_detail;
        this.task_detail =
          res.data.data[0].task_detail == []
            ? []
            : res.data.data[0].task_detail;
        this.google_map_location =
          res.data.data[0].google_map_location == ""
            ? ""
            : JSON.parse(res.data.data[0].google_map_location);
        // console.log("location--", this.google_map_location);
        if (res.data.data[0].other_images != "") {
          this.preview_other_images = res.data.data[0].other_images.split(",");
        }
        if (res.data.data[0].other_images != "") {
          this.other_images = res.data.data[0].other_images.split(",");
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });
    },
    fullImageGallery(image) {
      this.expandedImage = image;
    },
    closeImageGallery() {
      this.expandedImage = null;
    },
    deleteImage(image, index) {
      if (this.preview_other_images[index].includes("blob")) {
        this.preview_other_images.splice(index, 1);
        var index2 = this.other_images.length - index;
        this.new_other_images.splice(index2, index);
      } else {
        this.other_images.forEach((element, indexx) => {
          if (element.includes(image)) {
            this.other_images.splice(indexx, 1);
          }
        });
        this.preview_other_images.splice(index, 1);
      }
    },
    editNote(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Note", params: { id: id } });
    },
    editTask(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Task", params: { id: id } });
    },
    getAccount_contact_oppo() {
      // *************GET Accounts**********
      axios
        .get(axios.defaults.baseURL + "accounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountDetails = res.data.data;
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response.statusText == "Unauthorized") {
            this.$router.push("/login").then(() => {
              this.$router.go();
            });
          }
        });
      // *************GET Opportunity**********
      axios
        .get(axios.defaults.baseURL + "opportunities", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.opportunitiesDetails = res.data.data;
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response.statusText == "Unauthorized") {
            this.$router.push("/login").then(() => {
              this.$router.go();
            });
          }
        });
    },
    getContact() {
      axios
        .get(axios.defaults.baseURL + "get-contact/" + this.user.company, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contactsDetails = res.data.data;
          // alert(this.lead_details);
        })
        .catch((err) => {
          console.log("err", err);
        });

      //************** Account owner details
      axios
        .get(axios.defaults.baseURL + "get-account-owners", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountOwners = res.data.data;
        })
        .catch((err) => {
          console.log("err account owner", err);
        });
    },

    onFileChange(event) {
      this.preview_image = URL.createObjectURL(event.target.files[0]);
      this.image = event.target.files[0];
    },
    onFileChangeProfile(event) {
      this.profilePicture_preview = URL.createObjectURL(event.target.files[0]);
      this.profilePicture = event.target.files[0];
    },
    onFileChange2(event) {

      for (var i = 0; i < event.target.files.length; i++) {
        this.new_other_images.push(event.target.files[i]);
        this.preview_other_images.push(
          URL.createObjectURL(event.target.files[i])
        );
      }
      //   }
    },

    submit() {
      this.v$.company_name.$touch();
      this.v$.type.$touch();
      this.v$.address.$touch();
      this.v$.phone.$touch();
      this.v$.email.$touch();
      this.v$.account_owner.$touch();

      if (
        !this.v$.company_name.$invalid &&
        !this.v$.type.$invalid &&
        !this.v$.address.$invalid &&
        !this.v$.phone.$invalid &&
        !this.v$.email.$invalid &&
        !this.v$.account_owner.$invalid
      ) {
        (this.loading = true),
          (this.loadingS = false)
        var formData = new FormData();
        formData.append("name", this.state.company_name);
        formData.append("venue_name", this.venue_name);
        formData.append("type", this.state.type);
        formData.append("email", this.state.email);
        formData.append("address", this.state.address);
        formData.append("phone", this.state.phone);
        formData.append("corporate_number", this.state.corporate_number);
        formData.append("invoice_address", this.state.invoice_address);
        formData.append("invoice_email", this.invoice_email);
        formData.append("account_owner", this.state.account_owner);
        formData.append("commission", this.commission);
        formData.append("commission_model", this.commission_model);
        formData.append("meals_commission", this.meals_commission);
        formData.append("payment_terms", this.payment_terms);
        formData.append("default_language", this.default_language);
        if (this.note != "") {
          formData.append("note", JSON.stringify(this.note));
        } else {
          formData.append("note", "");
        }
        if (this.task != "") {
          formData.append("task", JSON.stringify(this.task));
        } else {
          formData.append("task", "");
        }
        formData.append(
          "google_map_location",
          JSON.stringify(this.google_map_location)
        );
        if (this.image != "") {
          formData.append("image", this.image);
        }
        for (var i = 0; i < this.new_other_images.length; i++) {
          formData.append("new_other_images[]", this.new_other_images[i]);
        }
        // formData.append("new_other_images", this.new_other_images);

        formData.append("other_images", this.other_images);
        formData.append("profile_picture", this.profilePicture);
        // for (var i = 0; i < this.other_images.length; i++) {
        //   formData.append("other_images", this.other_images[i]);
        // }
        formData.append("is_outside_partner", this.is_outside_partner);
        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("primary_contact", this.primary_contact);
        formData.append("predefined_comment1", this.predefined_comment1);
        formData.append("predefined_comment2", this.predefined_comment2);
        formData.append("predefined_comment3", this.predefined_comment3);
        formData.append("predefined_comment4", this.predefined_comment4);

        axios
          .post(
            axios.defaults.baseURL + "edit-account/" + this.user.company,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            // this.getAccountDetails()
            this.$store.dispatch("GetAccountsById",this.user.company)
            this.$store.dispatch("getUserDetails", {token: localStorage.getItem("token"), coming:''})

          })
          .catch((err) => {
            (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
          });
      } else {
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },
    saveNewNoteHandle(data) {
      this.note.push(data.note_id);
      this.note_detail.push({
        note_id: data.note_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    saveNewTaskHandle(data) {
      this.task.push(data.task_id),
        this.task_detail.push({
          task_id: data.task_id,
          subject: data.subject,
          assign_to: data.assign_to,
          comment: data.comment,
        });
    },
    route() {
      this.$router.push("/partner");
    },
    getAddressData(addressData, placeResultData, id) {
      this.google_map_location = {
        administrative_area_level_1: addressData.administrative_area_level_1,
        administrative_area_level_2: addressData.administrative_area_level_2,
        country: addressData.country,
        route: addressData.route,
        postal_code: addressData.postal_code,
        longitude: addressData.longitude,
        latitude: addressData.latitude,
      };
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.column {
  position: relative;
}

.column .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.column:hover .overlay {
  opacity: 1;
}
.column .overlay .text {
  background-color: rgba(0, 0, 0, 0.519);
  color: white;
}
.column .overlay .icon {
  width: 40px;
  height: 40px;
  border: none;
}
.column .overlay .delete {
  background-color: rgba(0, 0, 0, 0.347);
}
.column .overlay .icon i {
  font-size: 16px;
}
.column img {
  cursor: pointer;
}

/* Closable button inside the image */
.closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}
.container_img {
  position: relative;
  /* display: none; */
}
.column img:hover {
  opacity: 1;
}

.adjust-map .filter-btn, .primary-contact .filter-btn{
  height: auto;
  padding: 9px 39px;
}
@media only screen and (max-device-width: 576px) {
  .adjust-map .filter-btn{
    padding: 9px 20px;
  }
}
</style>
