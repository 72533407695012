<template>
  <!--**********************************
          Main wrapper start
      ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
    Content body start
***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.partners[0].dashboard[0].Dashboard}}</h2>
        </div>

        <div class="row">
          <div class="col-xl-12 col-xxl-12">
            <div class="row">
              <div class="col-sm-6">
                <router-link to="/p-quotes">
                  <div class="trans">
                    <div class="card transform" style="min-height: 305px">
                      <div class="card-body p-3 pb-0">
                        <div class="img d-flex justify-content-center">
                          <div class="icon mt-5">
                            <i class="fa-solid fa-paper-plane"></i>
                          </div>
                        </div>

                        <h3>{{ lan.partners[0].dashboard[0].Quotes}}</h3>
                        <p>
                          {{ lan.partners[0].dashboard[0].Accept_to_bid_on_quotes}}
                        </p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="col-sm-6">
                <router-link to="/p-commission">
                  <div class="trans">
                    <div class="card transform" style="min-height: 300px">
                      <div class="card-body p-3 pb-0">
                        <div class="img d-flex justify-content-center">
                          <div class="icon mt-5">
                            <i class="fa-solid fa-comment-dots"></i>
                          </div>
                        </div>

                        <h3>{{ lan.partners[0].dashboard[0].Commissions}}</h3>
                        <p>
                          {{ lan.partners[0].dashboard[0].Report_on_complete}}
                        </p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          
          <div class="lds-ellipsis" v-show="value">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

          <div v-if="!value && quoteDetails.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[0].dashboard[0].Quotes_to_respond_to}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[0].dashboard[0].Customer}}</th>
                          <th>{{ lan.partners[0].dashboard[0].date}}</th>
                          <th>{{ lan.partners[0].dashboard[0].Participants}}</th>
                          <th>{{ lan.partners[0].dashboard[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody v-if="quoteDetails && quoteDetails.length" 
                          v-for="(data, index) in quoteDetails"
                          :key="data.quote_id"
                          class="pointer">
                        <tr
                          v-if="
                            data.status == 'New' ||
                            data.status == 'Saved' || data.status == 'Preliminary Booking'
                          "
                        >
                        <td @click="edit(data.quote_id)">{{ data.quote_id }}</td>
                          <td v-if="data.customer_account_detail != ''" @click="edit(data.quote_id)">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else></td>
                          <td @click="edit(data.quote_id)">{{ data.date }}</td>
                          <td @click="edit(data.quote_id)">
                            {{ data.opportunity_detail.number_of_participants }}
                          </td>
                          <td class="pointer" data-toggle="modal" @click="(data.status == 'Preliminary Booking' || data.status == 'Preliminary Booked') ? openPreliminaryBooking(data) : edit(data.quote_id)">
                            <span
                              class="badge light"
                              :class="
                                data.status == 'New'
                                  ? 'badge-success' : data.status == 'Preliminary Booking' ? 'badge-success'
                                  : 'badge-warning'
                              "
                              >{{ data.status == 'Preliminary Booking' ? lan.Status.Respond_to_prel_booking : data.status == 'New' ? lan.Status.New : lan.Status.Saved}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <preliminaryBooking :apiData="preliminaryBookingData" @callQuote="getQuotes"/>

    <!-- <div id="priliminaryBookingModal" class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div>
          <div class="modal-dialog modal-dialog-centered" style="max-width: 1200px">
            <div class="content-body mainPrelim">
              <div class="container-fluid" style="padding-bottom: 40px">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="card">
                        <div
                        class="form-head mb-sm-3 mb-3 d-flex flex-wrap align-items-center pt-3"
                      >
                        <h2 class="font-w600 title mb-2 mr-auto">
                          {{ lan.partners[2].quote_page[0].Preliminary_booking }}
                        </h2>
                      </div>
                        <div class="card-header border-0 pb-0 justify-content-end">
                          <div class="card-body px-0">
                            <div class="table-responsive">
                              <table class="text-center tr-rounded order-tbl">
                                <thead>
                                  <tr>
                                    <th>
                                      {{ lan.partners[2].quote_page[0].Date }}
                                    </th>
                                    <th>
                                      {{ lan.partners[2].quote_page[0].Customer }}
                                    </th>
                                    <th>
                                      {{
                                        lan.partners[2].quote_page[0].Participants
                                      }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{{ PreBookDetails.date_of_conference_start }}</td>
                                    <td>{{ PreBookDetails.account_name }}</td>
                                    <td>{{ PreBookDetails.number_of_participants }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
    
                            <div class="table-responsive">
                              <table class="tr-rounded order-tbl t-ble w-auto">
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <th for="yes">
                                      {{
                                        lan.partners[2].quote_page[0]
                                          .We_have_made_a_preliminary_booking
                                      }}
                                    </th>
                                    <th class="text-center px-4">
                                      <input
                                        type="radio"
                                        class="my-2 radio"
                                        value="We have made a preliminary booking"
                                        v-model="PreBookDetails.response"
                                        name=""
                                        id="yes"
                                      />
                                    </th>
                                  </tr>
                                  <tr>
                                    <th for="no">
                                      {{
                                        lan.partners[2].quote_page[0]
                                          .We_have_not_made_a_preliminary_booking
                                      }}
                                    </th>
                                    <th class="text-center px-4">
                                      <input
                                        type="radio"
                                        class="my-2 radio"
                                        value="We have not made a preliminary booking"
                                        name=""
                                        id="no"
                                        v-model="PreBookDetails.response"
                                      />
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
    
                            <textarea
                              v-model="PreBookDetails.additional_information"
                              cols="10"
                              rows="6"
                            >
                                {{
                                lan.partners[2].quote_page[0]
                                  .Additional_information
                              }}
                              </textarea
                            >
    
                            <div
                              v-if="!PreBookDetails.disabled_button"
                              class="button d-flex justify-content-end mt-4"
                            >
                            <button
                              id="deletemodal"
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >{{ lan.admin[4].Opportunity_page[0].Cancel }}
                            </button>
                              <button
                                @click="saveBook()"
                                class="search-btn"
                                :disabled="!PreBookDetails.response"
                              >
                                <div class="lds-ring" v-show="PreBookDetails.value">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
    
                                <span v-show="!PreBookDetails.value">
                                  {{ lan.partners[2].quote_page[0].Submit }}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->


    <!--**********************************
    Content body end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from 'vuex'
import preliminaryBooking from "../../components/preliminaryBookingComponent";

export default {
  name: "Partner Dashboard",
  components: {preliminaryBooking},
  data() {
    return {
      quoteDetails: [],
      value: true,
      showPreliminaryBookingModal: false,
      preliminaryBookingData: null,
    };
  },

  computed:{
    ...mapState({
      PreBookDetails: (state) => state.priliminaryBooking
    }),
    ...mapGetters({
      preBookDetails: 'getPreliminaryBooking'
    }),
    lan(){
      return this.$store.getters.whichLanguage
    }
  },


  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast("You can't access Partner Portal", " Warning ", "warning_1");
  //     // this.$router.push("/login");
  //     setTimeout(() => {
  //       alert('First logout then Login partner portal.')
  //       if(window.history.state.back == null){
  //         this.$router.push("/login")
  //       }else{
  //         this.$router.back()
  //       }
  //     }, 3000);
  //   }
  // },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     this.getQuotes()
  },
  methods: {
    getQuotes(){
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(axios.defaults.baseURL + "new-quotes/" + user.company, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        this.quoteDetails.length = 0;
        res.data.data.forEach(element => {
          if(element.status == 'New' || element.status == 'Saved' ||  element.status == 'Preliminary Booking'){
            this.quoteDetails.push(element)
          }
          
        });
        // this.quoteDetails = res.data.data;
        this.value = false;
      })
      .catch((err) => {
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
    },
    openPreliminaryBooking(data){
      this.preliminaryBookingData = null
      this.$store.dispatch("GetPreliminaryBookingByID",{data: data, comeFrom: 'quote'}).then((res) => {
        console.log('then geting', res);
        this.preliminaryBookingData = this.preBookDetails;
        $('#priliminaryBookingModal').modal("show");
      })
    },
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({
          name: "Partner Edit Quotes",
          params: { id: id },
        });
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
